import { Button } from "@mui/material";
import { runInAction } from "mobx";
import React from "react";
import { t, tHtml } from "../../i18n/util";
import { CheckoutSession } from "../../network/APITypes";
import { checkoutStore } from "../../stores/CheckoutStore";
import { usePushRoute } from "../app/router/history";
import { CheckoutRoutes } from "../checkout/router/CheckoutRoutes";
import { ErrorMessage } from "./ErrorMessage";
import { Text } from "./Text";

export function PaymentTypeSelectionTermsDisclaimer(props: {
    paymentMethod: CheckoutSession["payment_method"];
    selectedInstallmentOptionId: string | null;
    contractDownloaded: boolean;
}) {
    const [error, setError] = React.useState("");
    const pushRoute = usePushRoute();
    const showError =
        error &&
        (!props.selectedInstallmentOptionId || (props.paymentMethod === "installment" && !props.contractDownloaded));

    const getNextRoute = () => {
        if (!checkoutStore.eCommerceSession) {
            return CheckoutRoutes.PERSONAL_DATA;
        }
        if (checkoutStore.allValuesSet) {
            return CheckoutRoutes.REVIEW;
        }
        return CheckoutRoutes.MISSING_DATA;
    };

    return (
        <div style={{ marginTop: 24 }}>
            <Text type="bodyS" textStyle="headingsDark">
                {tHtml(
                    props.paymentMethod === "installment"
                        ? "site.select_payment_type.installment_disclaimer"
                        : "site.select_payment_type.payment_type_disclaimer",
                )}
            </Text>
            {showError && <ErrorMessage>{error}</ErrorMessage>}
            <Button
                variant="contained"
                onClick={() => {
                    if (!props.selectedInstallmentOptionId && props.paymentMethod === "installment") {
                        setError(t("site.select_payment_type.error_no_rate_selected"));
                    } else if (!props.contractDownloaded && props.paymentMethod === "installment") {
                        setError(t("site.select_payment_type.error_contract_not_downloaded"));
                    } else {
                        runInAction(() => {
                            checkoutStore.generateFingerprint();
                            checkoutStore.selectedPaymentMethod = props.paymentMethod;
                        });
                        pushRoute(getNextRoute());
                    }
                }}
                style={{ marginTop: 16, width: "100%" }}
                data-id="paymentOption_nextButton"
            >
                {t("site.select_payment_type.next")}
            </Button>
        </div>
    );
}
