import { MenuItem, TextField, TextFieldProps } from "@mui/material";
import { FieldInputProps, FormikState, getIn, useFormikContext } from "formik";
import * as React from "react";
import { FieldError } from "./FieldError";

type IProps = TextFieldProps & {
    field: FieldInputProps<string>;
    onChange: () => void;
    form: FormikState<any>;
    showValidationErrorText?: boolean;
    selectOptions?: { value: string; label: string }[];
    "data-id": string;
    removeWhiteSpaces?: boolean;
};

export const CustomInputField = ({
    style,
    label,
    type,
    autoComplete,
    multiline,
    minRows,
    maxRows,
    required,
    form,
    field,
    "aria-label": ariaLabel,
    placeholder,
    showValidationErrorText = true,
    select,
    selectOptions,
    "data-id": dataId,
    removeWhiteSpaces,
    onChange,
}: IProps) => {
    const formikContext = useFormikContext();
    const fieldRef = React.useRef<HTMLDivElement>(null);
    const fieldError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && !!fieldError;

    // scroll to first validation error when user submits form
    React.useEffect(() => {
        const firstError = Object.keys(formikContext.errors)[0];
        if (formikContext.isSubmitting && firstError === field.name) {
            fieldRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [formikContext.isSubmitting, formikContext.errors, field.name]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (removeWhiteSpaces) {
            // https://stackoverflow.com/a/6623263
            event.target.value = event.target.value.replace(/ /g, "");
        }

        field.onChange(event);

        if (onChange) {
            onChange();
        }
    };

    return (
        <div style={style}>
            <TextField
                ref={fieldRef}
                select={select}
                label={required ? `${label} *` : label}
                value={field.value}
                name={field.name}
                onBlur={field.onBlur}
                onChange={handleChange}
                fullWidth
                type={type}
                autoComplete={autoComplete}
                error={showError}
                margin="dense"
                aria-label={ariaLabel}
                variant="outlined"
                placeholder={placeholder}
                multiline={multiline}
                minRows={minRows}
                maxRows={maxRows}
                data-id={dataId}
                // For date inputs shrink does not work correctly so explicitly set it.
                // See here: https://mui.com/material-ui/react-text-field/#shrink
                InputLabelProps={{ shrink: type === "date" ? true : undefined }}
            >
                {selectOptions?.map((selectOption) => (
                    <MenuItem
                        key={selectOption.value}
                        value={selectOption.value}
                        data-id={`${dataId}_option_${selectOption.value}`}
                    >
                        {selectOption.label}
                    </MenuItem>
                ))}
            </TextField>
            {showValidationErrorText && <FieldError>{showError ? fieldError : ""}</FieldError>}
        </div>
    );
};
