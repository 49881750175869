import * as Config from "../config";
import { APIError } from "../errors/APIError";
import { CheckoutSessionDetail, CreateCheckoutSessionOrder, ResponseCheckoutSessionOrder } from "./APITypes";

export const STATUS_CODE_UNAUTHORIZED = 401;

export const API = {
    async getCheckoutSession(options: { sessionId: string }): Promise<CheckoutSessionDetail> {
        try {
            const response = await fetch(
                `${Config.API_BASE_URL}/v1/consumer_checkout/checkout_sessions/${options.sessionId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );

            const json = await response.json();
            if (!response.ok) {
                const apiError = new APIError(response.status, json.title, json.errors);
                throw apiError;
            }

            return json;
        } catch (error) {
            throw error;
        }
    },
    async postCheckOutSession(options: {
        sessionId: string;
        body: CreateCheckoutSessionOrder;
    }): Promise<ResponseCheckoutSessionOrder> {
        try {
            const response = await fetch(
                `${Config.API_BASE_URL}/v1/consumer_checkout/checkout_sessions/${options.sessionId}/order`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(options.body),
                },
            );

            const json = await response.json();

            if (!response.ok) {
                throw new APIError(response.status, json.title, json.errors);
            }

            return json;
        } catch (error) {
            throw error;
        }
    },
};
