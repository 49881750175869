import { createTheme, Theme } from "@mui/material";
import * as React from "react";
import { Colors } from "../components/util/Colors";
import { MOBILE_BREAKPOINT } from "../config";
import { CheckoutSessionDetailBrandingInformation } from "../network/APITypes";

export const useBranding = (
    failedToLoadSession: boolean,
    brandingInformation?: CheckoutSessionDetailBrandingInformation,
) => {
    const [currentTheme, setCurrentTheme] = React.useState<Theme>();

    React.useEffect(() => {
        if (!failedToLoadSession && !brandingInformation) {
            return;
        }

        const branding = createTheme({
            typography: {
                fontFamily: ["Open Sans", "sans-serif"].join(","),
            },
            palette: {
                primary: {
                    main: brandingInformation?.primary_color ?? Colors.DEFAULT_BRANDING_PRIMARY_500,
                },
                secondary: {
                    main: brandingInformation?.secondary_color ?? Colors.DEFAULT_BRANDING_SECONDARY_500,
                },
            },
            components: {
                MuiButton: {
                    defaultProps: {
                        disableRipple: true,
                        disableElevation: true,
                    },
                    styleOverrides: {
                        root: {
                            textTransform: "unset",
                            padding: "11px 32px",
                            fontSize: 16,
                            lineHeight: "22px",
                            fontWeight: 600,
                            borderRadius: 100,
                        },
                        containedPrimary: {
                            color: brandingInformation?.primary_text_color ?? Colors.LIGHT,
                            "&:hover": {
                                color: brandingInformation?.primary_text_color ?? Colors.LIGHT,
                                background:
                                    brandingInformation?.primary_color_600 ?? Colors.DEFAULT_BRANDING_PRIMARY_600,
                                [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
                                    background:
                                        brandingInformation?.primary_color ?? Colors.DEFAULT_BRANDING_PRIMARY_500,
                                },
                            },
                            "&:active": {
                                color: brandingInformation?.primary_text_color ?? Colors.LIGHT,
                                background:
                                    brandingInformation?.primary_color_700 ?? Colors.DEFAULT_BRANDING_PRIMARY_700,
                            },
                            "&:disabled": {
                                color: Colors.LIGHT,
                                background: Colors.GREY_500,
                            },
                        },
                        containedSecondary: {
                            color: brandingInformation?.secondary_text_color ?? Colors.HEADINGS_DARK_HEX,
                            "&:hover": {
                                color: brandingInformation?.secondary_text_color ?? Colors.DARK,
                                background:
                                    brandingInformation?.secondary_color_600 ?? Colors.DEFAULT_BRANDING_SECONDARY_600,
                                [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
                                    background:
                                        brandingInformation?.secondary_color ?? Colors.DEFAULT_BRANDING_SECONDARY_500,
                                },
                            },
                            "&:active": {
                                color: brandingInformation?.secondary_text_color ?? Colors.DARK,
                                background:
                                    brandingInformation?.secondary_color_700 ?? Colors.DEFAULT_BRANDING_SECONDARY_700,
                            },
                            "&:disabled": {
                                color: Colors.LIGHT,
                                background: Colors.GREY_500,
                            },
                        },
                    },
                },
                MuiTextField: {
                    defaultProps: {
                        size: "small",
                        variant: "outlined",
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            "& fieldset": {
                                borderColor: Colors.GREY_500_HEX,
                                borderRadius: 4,
                            },
                        },
                        input: {
                            padding: "8px 12px",
                        },
                        inputMultiline: {
                            padding: 0,
                        },
                    },
                },
                MuiAccordion: {
                    styleOverrides: {
                        root: {
                            "&:before": {
                                backgroundColor: "white",
                            },
                        },
                    },
                },
            },
        });

        setCurrentTheme(branding);
    }, [failedToLoadSession, brandingInformation]);

    return { branding: currentTheme };
};
