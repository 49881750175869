import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";
import { FieldInputProps, FormikState, getIn, useFormikContext } from "formik";
import * as React from "react";
import { FieldError } from "./FieldError";

type IProps = CheckboxProps & {
    field: FieldInputProps<string>;
    onChange: () => void;
    form: FormikState<any>;
    showValidationErrorText?: boolean;
    "data-id": string;
    label: string;
    checked: boolean;
};

export const CustomCheckbox = ({
    style,
    required,
    form,
    field,
    "aria-label": ariaLabel,
    placeholder,
    showValidationErrorText = true,
    "data-id": dataId,
    onChange,
    label,
    checked,
}: IProps) => {
    const formikContext = useFormikContext();
    const fieldRef = React.useRef<HTMLButtonElement>(null);
    const fieldError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && !!fieldError;

    // scroll to first validation error when user submits form
    React.useEffect(() => {
        const firstError = Object.keys(formikContext.errors)[0];
        if (formikContext.isSubmitting && firstError === field.name) {
            fieldRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [formikContext.isSubmitting, formikContext.errors, field.name]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        field.onChange(event);

        if (onChange) {
            onChange();
        }
    };

    return (
        <div style={style}>
            <FormControlLabel
                label={label}
                control={
                    <Checkbox
                        ref={fieldRef}
                        required={required}
                        value={field.value}
                        name={field.name}
                        onBlur={field.onBlur}
                        onChange={handleChange}
                        aria-label={ariaLabel}
                        placeholder={placeholder}
                        data-id={dataId}
                        checked={checked}
                    />
                }
            />

            {showValidationErrorText && <FieldError>{showError ? fieldError : ""}</FieldError>}
        </div>
    );
};
