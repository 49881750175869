import { useEffect } from "react";
import { DEPLOYMENT_ENV } from "../config";
import { checkoutStore } from "../stores/CheckoutStore";
import useScript from "./useScript";

const useFingerprinting = () => {
    const environment = DEPLOYMENT_ENV === "production" ? "p" : "t";

    const status = useScript({
        src: checkoutStore.fingerprint
            ? `https://d.payla.io/dcs/${checkoutStore.fingerprint?.paylaPartnerId}/${checkoutStore.fingerprint?.partnerMerchandId}/dcs.js`
            : "",
        id: checkoutStore.fingerprint?.sessionId,
    });

    useScript({
        src: checkoutStore.fingerprint
            ? `https://d.payla.io/dcs/dcs.css?st=${checkoutStore.fingerprint?.deviceToken}&pi=${checkoutStore.fingerprint?.paylaPartnerId}&psi=${checkoutStore.fingerprint?.partnerMerchandId}&e=${environment}`
            : "",
        element: "link",
        id: checkoutStore.fingerprint?.sessionId,
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (status === "ready" && typeof (window as any).paylaDcs !== "undefined") {
                const paylaDcs = (window as any).paylaDcs;
                paylaDcs.init(environment, checkoutStore.fingerprint?.deviceToken);

                clearInterval(intervalId);
            }
        }, 100);

        return () => {
            clearInterval(intervalId);
        };
    }, [environment, status]);
};

export default useFingerprinting;
