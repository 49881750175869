import { styled } from "@mui/material";
import * as React from "react";

export type IIconNames = "download" | "chevronRight" | "checkCircle" | "intercom" | "close" | "circle" | "arrowLeft";

const icons: IIconMap = {
    arrowLeft: {
        fileName: "ic_arrow_left.svg",
        path: (
            <g fill="none">
                <path
                    fill="#000000"
                    d="M1.05775 11.732C1.00126 11.8691 0.986548 12.0199 1.01545 12.1653C1.04435 12.3108 1.11558 12.4444 1.2202 12.5495L4.21975 15.5487C4.3612 15.6853 4.55065 15.7609 4.7473 15.7592C4.94395 15.7575 5.13206 15.6786 5.27111 15.5395C5.41017 15.4005 5.48905 15.2124 5.49076 15.0157C5.49247 14.8191 5.41687 14.6296 5.28025 14.4882L3.56058 12.7684L23.25 12.7684C23.4489 12.7684 23.6397 12.6894 23.7803 12.5488C23.921 12.4081 24 12.2173 24 12.0184C24 11.8195 23.921 11.6288 23.7803 11.4881C23.6397 11.3474 23.4489 11.2684 23.25 11.2684L3.56058 11.2684L5.28025 9.54868C5.35188 9.4795 5.40902 9.39674 5.44833 9.30524C5.48763 9.21373 5.50832 9.11532 5.50919 9.01573C5.51005 8.91615 5.49108 8.81739 5.45337 8.72522C5.41566 8.63305 5.35997 8.54931 5.28955 8.47889C5.21913 8.40847 5.13539 8.35278 5.04321 8.31507C4.95104 8.27736 4.85228 8.25838 4.7527 8.25924C4.65311 8.26011 4.5547 8.2808 4.4632 8.32011C4.37169 8.35941 4.28893 8.41655 4.21975 8.48818L1.2202 11.4877C1.15048 11.5576 1.09527 11.6406 1.05775 11.732Z"
                />
            </g>
        ),
    },
    download: {
        fileName: "ic_download.svg",
        path: (
            <g fill="none" fillRule="evenodd">
                <path
                    d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                    stroke="#4221C6"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7 10L12 15L17 10"
                    stroke="#4221C6"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M12 15V3" stroke="#4221C6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        ),
    },
    chevronRight: {
        fileName: "ic_chevron_right.svg",
        path: (
            <g fill="none" fillRule="evenodd">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.97013 18C8.43489 18 8 17.5732 8 17.0377C8 16.7699 8.10872 16.5272 8.30107 16.3347L12.7001 12.0084L8.30107 7.67364C8.10872 7.4728 8 7.23013 8 6.96234C8 6.42678 8.43489 6 8.97013 6C9.23775 6 9.46356 6.09205 9.64755 6.27615L14.6655 11.2385C14.8913 11.4561 15 11.7071 15 12C15 12.2929 14.8913 12.5356 14.6571 12.7615L9.64755 17.7239C9.46356 17.908 9.23775 18 8.97013 18Z"
                    fill="black"
                />
            </g>
        ),
    },
    checkCircle: {
        fileName: "ic_check-circle.svg",
        path: (
            <g fill="none" fillRule="evenodd">
                <path
                    d="M22 11.0801V12.0001C21.9988 14.1565 21.3005 16.2548 20.0093 17.9819C18.7182 19.7091 16.9033 20.9726 14.8354 21.584C12.7674 22.1954 10.5573 22.122 8.53447 21.3747C6.51168 20.6274 4.78465 19.2462 3.61096 17.4372C2.43727 15.6281 1.87979 13.4882 2.02168 11.3364C2.16356 9.18467 2.99721 7.13643 4.39828 5.49718C5.79935 3.85793 7.69279 2.71549 9.79619 2.24025C11.8996 1.76502 14.1003 1.98245 16.07 2.86011"
                    stroke="#23022E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22 4L12 14.01L9 11.01"
                    stroke="#23022E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        ),
    },
    intercom: {
        fileName: "",
        path: (
            <g id="Layer-1">
                <path
                    d="M960 1024C960 1024 809.162 964.642 687.129 917.094L174 917.094C113.255 917.094 64 864.87 64 800.461L64 116.634C64 52.224 113.255 0 174 0L849.968 0C910.713 0 959.968 52.224 959.968 116.634L959.968 705.57L960 705.57L960 1024ZM827.565 640.58C816.99 627.537 798.531 626.028 786.215 637.201C785.255 638.054 689.721 723.012 511.968 723.012C336.423 723.012 239.417 638.669 237.689 637.133C225.374 626.014 206.964 627.536 196.403 640.546C191.332 646.8 188.814 654.955 189.409 663.198C190.004 671.441 193.663 679.089 199.571 684.442C204.116 688.538 312.772 785.237 511.968 785.237C711.196 785.237 819.852 688.538 824.397 684.442C830.295 679.09 833.948 671.449 834.543 663.214C835.137 654.98 832.626 646.832 827.565 640.58Z"
                    fill="#fff"
                    opacity="1"
                    stroke="none"
                />
            </g>
        ),
        viewBoxSize: 1024,
    },
    close: {
        fileName: "ic_close.svg",
        path: (
            <g fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.6566 4.92893L19.0708 6.34315L13.4137 11.9999L19.0708 17.6569L17.6566 19.0711L11.9997 13.4139L6.34292 19.0711L4.92871 17.6569L10.5847 11.9999L4.92871 6.34315L6.34292 4.92893L11.9997 10.5849L17.6566 4.92893Z"
                    fill="white"
                />
            </g>
        ),
    },
    circle: {
        fileName: "ic_x-circle",
        path: (
            <g fill="none">
                <path
                    d="M12.0005 22.5005C17.5233 22.5005 22.0005 18.0233 22.0005 12.5005C22.0005 6.97764 17.5233 2.50049 12.0005 2.50049C6.47764 2.50049 2.00049 6.97764 2.00049 12.5005C2.00049 18.0233 6.47764 22.5005 12.0005 22.5005Z"
                    stroke="#E8637E"
                    strokeWidth="2.83333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.001 9.49951L9.00098 15.4995"
                    stroke="#E8637E"
                    strokeWidth="2.83333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.00098 9.49951L15.001 15.4995"
                    stroke="#E8637E"
                    strokeWidth="2.83333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        ),
    },
};

type IContainerProps = {
    color?: string;
    strokeColor?: string;
    hoverColor?: string;
    onClick?: any;
    disabled?: boolean;
    useCurrentColor?: boolean;
};

const BlockContainer = styled("div", {
    shouldForwardProp: (prop: string) => prop !== "useCurrentColor" && prop !== "hoverColor",
})(({ disabled, onClick, hoverColor, color, useCurrentColor }: IContainerProps) => ({
    userSelect: "none",
    cursor: disabled || !onClick ? undefined : "pointer",

    "& > svg": {
        display: "block",
    },

    "& > svg *[fill]:not([fill=none])": {
        transition: hoverColor ? "fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms" : undefined,
        fill: useCurrentColor ? "currentColor" : color,
    },

    "&: hover > svg *[fill]:not([fill=none])": {
        fill: hoverColor,
    },

    "&: hover > svg *[stroke]:not([stroke=none])": {
        stroke: hoverColor,
    },

    "& > svg *[stroke]:not([stroke=none])": {
        transition: hoverColor ? "stroke 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms" : undefined,
        stroke: useCurrentColor ? "currentColor" : color,
    },
}));

const InlineBlockContainer = styled(BlockContainer)({
    display: "inline-block",
    lineHeight: 0,
    verticalAlign: "bottom",
    "& > svg": {
        display: "inline-block",
    },
});

type IIconMap = {
    [k in IIconNames]: {
        fileName: string;
        viewBoxSize?: number;
        path: React.ReactNode;
    };
};

export type IconNames = keyof typeof icons;

export type IProps = {
    name: IconNames;
    title?: string;
    onClick?: any;
    disabled?: boolean;
    color?: string;
    hoverColor?: string;
    useCurrentColor?: boolean;
    size?: string | number;
    style?: React.CSSProperties;
    className?: string;
    display?: "block" | "inline-block";
    "data-id"?: string;
};
export const Icon = ({
    name,
    title,
    onClick,
    disabled,
    color,
    hoverColor,
    useCurrentColor,
    size = 24,
    style,
    className,
    "data-id": dataId,
    display = "inline-block",
}: IProps) => {
    const Container = display === "block" ? BlockContainer : InlineBlockContainer;
    const viewBoxSize = icons[name].viewBoxSize || 24;
    return (
        <Container
            data-id={dataId}
            onClick={disabled ? undefined : onClick}
            disabled={disabled}
            color={disabled ? "#A1A1A1" : color}
            // the props have to be lowercase to appear on DOM
            hoverColor={disabled ? undefined : hoverColor}
            useCurrentColor={disabled ? undefined : useCurrentColor}
            className={className}
            style={style}
        >
            <svg
                viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
            >
                {title && <title>{title}</title>}
                {icons[name].path}
            </svg>
        </Container>
    );
};
