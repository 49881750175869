import React from "react";

export const SiteWrapper = (props: { children: React.ReactNode }) => {
    return (
        <div
            style={{
                padding: "40px 16px",
                display: "flex",
                flexDirection: "column",
                maxWidth: 600,
                width: "100%",
                margin: "0 auto 0 auto",
                flexGrow: 1,
            }}
        >
            {props.children}
        </div>
    );
};
