import { Link, LinkProps } from "@mui/material";
import { styled } from "@mui/system";

type LinkButtonProps = {
    disabled?: boolean;
    className?: string;
    underline?: string;
};

const StyledLinkButton = styled(Link)({
    fontWeight: 400,
    lineHeight: "16px",
    maxWidth: "fit-content",
    padding: "4px 0px",
});

export const LinkButton = ({
    onClick,
    style,
    color,
    children,
    disabled,
    className,
    underline = "none",
    href,
}: LinkProps & LinkButtonProps) => (
    <StyledLinkButton
        className={className ?? "bodyM"}
        color={color ?? "inherit"}
        style={{
            cursor: "pointer",
            pointerEvents: disabled ? "none" : "auto",
            ...style,
        }}
        target="_blank"
        onClick={onClick}
        underline={underline}
        disabled={disabled}
        href={href}
    >
        {children}
    </StyledLinkButton>
);
