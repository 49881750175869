import React from "react";

export const Box = (props: {
    children: React.ReactNode | React.ReactNode[];
    style?: React.CSSProperties;
    onClick?: () => void;
}) => {
    return (
        <div
            onClick={props.onClick}
            style={{
                background: "var(--color-white)",
                padding: "16px 24px",
                display: "flex",
                flexDirection: "column",
                borderRadius: 8,
                ...props.style,
            }}
        >
            {props.children}
        </div>
    );
};
