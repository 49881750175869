import { LoadingButton } from "@mui/lab";
import { observer } from "mobx-react";
import React from "react";
import { MessageIDS, t } from "../../../i18n/util";
import { PaymentMethodEnum } from "../../../network/APITypes";
import { checkoutStore } from "../../../stores/CheckoutStore";
import { usePushRoute } from "../../app/router/history";
import { BackButton } from "../../ui/BackButton";
import { ErrorBox } from "../../ui/ErrorBox";
import { PersonalInformationBox } from "../../ui/PersonalInformationBox";
import { ReviewPaymentPlan } from "../../ui/ReviewPaymentPlan";
import { SectionHeader } from "../../ui/SectionHeader";
import { SEPADirectDebitMandate } from "../../ui/SEPADirectDebitMandate";
import { CheckoutRoutes } from "../router/CheckoutRoutes";

export const CheckoutReviewSite = observer(() => {
    const pushRoute = usePushRoute();
    const [loading, setLoading] = React.useState(false);

    const sectionHeaderTitles: { [key in PaymentMethodEnum]: MessageIDS } = {
        invoice: "site.review.title_pay_later",
        installment: "site.review.title_installment",
        direct_debit: "site.review.title_pay_now",
    };

    React.useEffect(() => {
        if (!checkoutStore.hasEnteredPersonalInformation()) {
            pushRoute(CheckoutRoutes.PERSONAL_DATA);
        }
        if (!checkoutStore.hasEnteredPaymentData() && !(checkoutStore.selectedPaymentMethod === "invoice")) {
            pushRoute(CheckoutRoutes.PAYMENT_DATA);
        }
    }, [pushRoute]);

    const handleSubmitPayment = async () => {
        try {
            setLoading(true);
            const status = await checkoutStore.postCheckoutSession();
            if (status === "accepted" || status === "created") {
                pushRoute(CheckoutRoutes.SUCCESS);
            } else if (!status || status === "cancelled" || status === "expired") {
                // If we have an API error -> redirect to error page, otherwise display "try again message"
                if (checkoutStore.sendCheckoutSessionError) {
                    pushRoute(CheckoutRoutes.ERROR);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            checkoutStore.clear();
            setLoading(false);
        }
    };

    let loadingButtonTitle = "";

    if (checkoutStore.selectedPaymentMethod === "direct_debit") {
        loadingButtonTitle = t("site.review.submit_pay_now");
    } else if (checkoutStore.selectedPaymentMethod === "installment") {
        if (checkoutStore.checkoutSession?.checkout_configuration.receipt_type === "digital") {
            loadingButtonTitle = t("site.review.submit_installment.digital");
        } else {
            loadingButtonTitle = t("site.review.submit_installment.print");
        }
    } else {
        loadingButtonTitle = t("site.review.submit_pay_later");
    }

    if (checkoutStore.failedToSendCheckoutSession) {
        loadingButtonTitle = t("checkout_session.try_again");
    }

    const getBackRoute = () => {
        if (checkoutStore.eCommerceSession) {
            if (checkoutStore.allValuesSet) {
                return CheckoutRoutes.SELECT_PAYMENT;
            }
            return CheckoutRoutes.MISSING_DATA;
        }
        if (checkoutStore.selectedPaymentMethod === "invoice") {
            return CheckoutRoutes.PERSONAL_DATA;
        }
        return CheckoutRoutes.PAYMENT_DATA;
    };

    return (
        <>
            <BackButton
                to={getBackRoute()}
                onClick={() => {
                    // PAYL-2970: Reset error state when going back
                    checkoutStore.setSendCheckoutSessionError(false);
                }}
                data-id="review_backButton"
            />
            <SectionHeader data-id="review_title">
                {checkoutStore.selectedPaymentMethod && t(sectionHeaderTitles[checkoutStore.selectedPaymentMethod])}
            </SectionHeader>
            <PersonalInformationBox personalInformation={checkoutStore.personalInformation} />
            {(checkoutStore.selectedPaymentMethod === "installment" ||
                checkoutStore.selectedPaymentMethod === "invoice") && (
                <ReviewPaymentPlan
                    totalAmount={checkoutStore.getTotalAmount()}
                    installmentOption={checkoutStore.getSelectedInstallmentOption()}
                    selectedPaymentMethod={checkoutStore.selectedPaymentMethod}
                    selectedInstallmentOptionId={checkoutStore.selectedInstallmentOptionId}
                    style={{ marginTop: 24 }}
                />
            )}
            {checkoutStore.selectedPaymentMethod !== "invoice" && (
                <SEPADirectDebitMandate iban={checkoutStore.iban} style={{ marginTop: 40 }} />
            )}
            {checkoutStore.failedToSendCheckoutSession && (
                <ErrorBox style={{ marginTop: 24 }}>{t("checkout_session.failed_to_send")}</ErrorBox>
            )}
            <LoadingButton
                onClick={handleSubmitPayment}
                style={{ marginTop: 64, alignSelf: "center" }}
                variant="contained"
                color="secondary"
                loading={loading}
                data-id="review_submit"
                fullWidth
            >
                {loadingButtonTitle}
            </LoadingButton>
        </>
    );
});
