import { styled, useTheme } from "@mui/material";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../i18n/util";
import { CheckoutSessionAmount, CheckoutInstallmentOption, PaymentOption } from "../../network/APITypes";
import { checkoutStore } from "../../stores/CheckoutStore";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";
import { Divider } from "./Divider";
import { FormattedAmount } from "./FormattedAmount";
import { FormattedPercentage } from "./FormattedPercentage";
import { Text } from "./Text";

const PaymentSelectionItemContainer = styled("div")(() => {
    return {
        "&:hover": {
            background: "var(--color-grey-200)",
        },
    };
});

function InstallmentOptionDetailsItem(props: { children: React.ReactNode; style?: React.CSSProperties }) {
    return <div style={{ display: "flex", justifyContent: "space-between", ...props.style }}>{props.children}</div>;
}

function InstallmentOptionDetails(props: {
    basketAmount?: CheckoutSessionAmount;
    installmentOption: CheckoutInstallmentOption;
    style?: React.CSSProperties;
}) {
    const options = [
        {
            name: t("payment_method.installment.nominal_interest_rate"),
            value: <FormattedPercentage amount={props.installmentOption.nominal_interest_rate} />,
        },
        {
            name: t("payment_method.installment.effective_interest_rate"),
            value: <FormattedPercentage amount={props.installmentOption.effective_interest_rate} />,
        },
        {
            name: t("payment_method.installment.basket_amount"),
            value: <FormattedAmount amount={props.basketAmount} />,
        },
        {
            name: t("payment_method.installment.total_amount"),
            value: <FormattedAmount amount={checkoutStore.getTotalAmount()} />,
        },
    ];
    return (
        <div style={{ display: "flex", flexDirection: "column", ...props.style }}>
            {options.map((option, index) => {
                return (
                    <InstallmentOptionDetailsItem
                        style={{ marginTop: index === 0 ? 0 : 8 }}
                        key={`${option.name}-${index}`}
                    >
                        <Text type="bodySBold" textStyle="headingsDark">
                            {option.name}
                        </Text>

                        <Text type="bodySBold" textStyle="headingsDark">
                            {option.value}
                        </Text>
                    </InstallmentOptionDetailsItem>
                );
            })}
        </div>
    );
}

function PaymentSelectionItem(props: {
    installmentOption: CheckoutInstallmentOption;
    selected?: boolean;
    index?: number;
    onDownloadContract: () => void;
}) {
    const theme = useTheme();
    return (
        <PaymentSelectionItemContainer
            style={{
                borderRadius: 8,
                margin: props.selected ? -1 : 0,
                padding: "16px 24px",
                border: props.selected ? `1px solid ${theme.palette.primary.main}` : undefined,
                background: props.selected ? "var(--color-grey-200)" : undefined,
                zIndex: props.selected ? 2 : 1,
                cursor: props.selected ? "initial" : "pointer",
                position: "relative",
            }}
            onClick={() => {
                runInAction(() => {
                    checkoutStore.selectedInstallmentOptionId = props.installmentOption.installment_option_id;
                });
            }}
            data-id={`installmentOption_${props.index}`}
        >
            <Text
                type="bodySBold"
                style={{ color: props.selected ? theme.palette.primary.main : Colors.HEADINGS_DARK_HEX }}
            >
                <FormattedAmount amount={props.installmentOption.monthly_amount} />
                {t("payment_method.installment.rate_selection.title", {
                    numberOfPayments: props.installmentOption.number_of_payments,
                })}
            </Text>
            {props.selected && props.installmentOption._links.credit_information.href && (
                <div
                    onClick={() => {
                        props.onDownloadContract();
                        setTimeout(() => {
                            // Don't open the contract in Cypress as it will fail the test
                            // https://github.com/cypress-io/cypress/issues/24775
                            if (!(window as any).Cypress)
                                window.open(props.installmentOption._links.credit_information.href, "_blank");
                        });
                    }}
                    style={{ cursor: "pointer" }}
                    data-id="downloadContract"
                >
                    <Divider style={{ margin: "12px 0" }} />
                    <Text type="bodyXSBold" style={{ color: theme.palette.primary.main }}>
                        <Icon name="download" style={{ marginRight: 12 }} color={theme.palette.primary.main} />
                        {t("payment_method.installment.rate_selection.disclaimer")}
                    </Text>
                </div>
            )}
        </PaymentSelectionItemContainer>
    );
}

export const PaymentRateSelection = observer(
    (props: {
        basketAmount?: CheckoutSessionAmount;
        installmentOptions: PaymentOption["installment_options"];
        style?: React.CSSProperties;
        onDownloadContract: () => void;
    }) => {
        let lastSelected = false;
        const selectedInstallmentOption = checkoutStore.getSelectedInstallmentOption();

        return (
            <div>
                <div
                    style={{
                        border: "1px solid var(--color-grey-500)",
                        borderRadius: 8,
                        display: "flex",
                        flexDirection: "column",
                        background: "var(--color-white)",
                        ...props.style,
                    }}
                >
                    {props.installmentOptions?.map((installmentOption, index) => {
                        const selected =
                            installmentOption.installment_option_id.trim() ===
                            checkoutStore.selectedInstallmentOptionId?.trim(); // TODO remove trim() when backend is fixed

                        const isFirst = index === 0;
                        const showDivider = !selected && !isFirst && !lastSelected;
                        lastSelected = selected;
                        return (
                            <div key={installmentOption.installment_option_id}>
                                {showDivider && <Divider />}
                                <PaymentSelectionItem
                                    installmentOption={installmentOption}
                                    selected={selected}
                                    onDownloadContract={props.onDownloadContract}
                                    index={index}
                                />
                            </div>
                        );
                    })}
                </div>

                {selectedInstallmentOption && (
                    <InstallmentOptionDetails
                        basketAmount={props.basketAmount}
                        installmentOption={selectedInstallmentOption}
                        style={{ marginTop: 24 }}
                    />
                )}
            </div>
        );
    },
);
