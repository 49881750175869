import { observer } from "mobx-react";
import { Amount } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

function currencySymbolForCode(currencyCode: string) {
    const amount = 0;
    return amount
        .toLocaleString("en", {
            style: "currency",
            currency: currencyCode,
            currencyDisplay: "symbol",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
        .replace("0.00", "");
}

type FormattedAmountProps = {
    amount?: Amount;
};

export function getFormattedAmount(amount?: Amount) {
    if (!amount) {
        return "-";
    }

    const currencyString = Number.parseFloat(amount.value).toLocaleString(generalStore.locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });

    const currencySymbol = currencySymbolForCode(amount.currency);

    return generalStore.locale === "de" ? `${currencyString} ${currencySymbol}` : `${currencySymbol} ${currencyString}`;
}

export const FormattedAmount = observer(({ amount }: FormattedAmountProps) => {
    return <span style={{ whiteSpace: "nowrap" }}>{getFormattedAmount(amount)}</span>;
});
