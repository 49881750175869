import { Link } from "@mui/material";
import { PrimitiveType } from "intl-messageformat";
import { action, runInAction } from "mobx";
import { createIntl, createIntlCache, FormattedMessage } from "react-intl";
import { Colors } from "../components/util/Colors";
import { checkoutStore } from "../stores/CheckoutStore";
import { debugStore } from "../stores/DebugStore";
import { generalStore } from "../stores/GeneralStore";
import german from "./de.json";
import english from "./en.json";
import { DEFAULT_LOCALE } from "./i18n_defaults";
import { ILocales } from "./ILocales";

type IMessages = {
    [locale in ILocales]: {
        [message: string]: string;
    };
};

const intlMessages: IMessages = {
    de: german,
    en: english,
};

const cache = createIntlCache();

let intl = createIntl(
    {
        locale: DEFAULT_LOCALE,
        messages: intlMessages[DEFAULT_LOCALE],
    },
    cache,
);

export type MessageIDS = keyof typeof german;

export const MessageIdsTypeguard = (messageId: string): messageId is MessageIDS => {
    return messageId in german;
};

const setLocale = action("setLocale", (locale: ILocales) => {
    intl = createIntl(
        {
            locale: locale,
            messages: intlMessages[locale],
        },
        cache,
    );

    generalStore.locale = locale;
    document.documentElement.lang = locale;

    console.log(`%cSet locale to "${locale}".`, "background: #eee; color: #666;");
});

function returnString(messageId: MessageIDS, translation: string) {
    const showStringKeys = debugStore.showStringKeys;
    return showStringKeys ? `${String(messageId)} (${translation})` : translation;
}

const t = (messageId: MessageIDS, values?: Record<string, PrimitiveType>) => {
    return returnString(messageId, intl.formatMessage({ id: messageId }, values));
};

export const tHtml = (messageId: MessageIDS, values?: Record<string, any>) => {
    const showStringKeys = debugStore.showStringKeys;

    return showStringKeys ? (
        `${String(messageId)} (${intl.formatMessage({ id: messageId })})`
    ) : (
        <FormattedMessage
            key={messageId}
            id={messageId}
            values={{
                ...values,
                b: (chunks) => {
                    return <b>{chunks}</b>;
                },
                br: () => {
                    return <br />;
                },
                div: (chunks) => {
                    return <div>{chunks}</div>;
                },
                sup: (chunks) => {
                    return <sup>{chunks}</sup>;
                },
                p: (chunks) => {
                    return <p>{chunks}</p>;
                },
                a: (chunks) => {
                    const link = values?.link;
                    return (
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            {chunks}
                        </a>
                    );
                },
                aTermsOfService: (chunks) => {
                    const link: MessageIDS = "links.payment_conditions";
                    return (
                        <a
                            href={
                                checkoutStore.checkoutSession?.shop_information.terms_and_conditions_url ||
                                intl.formatMessage({ id: link })
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {chunks}
                        </a>
                    );
                },
                aPrivacyPolicy: (chunks) => {
                    const link: MessageIDS = "links.privacy_policy";
                    return (
                        <a
                            href={
                                checkoutStore.checkoutSession?.shop_information.data_protection_url ||
                                intl.formatMessage({ id: link })
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {chunks}
                        </a>
                    );
                },
                aAdditionalPrivacyPolicy: (chunks) => {
                    const link: MessageIDS = "links.terms_of_service";
                    return (
                        <a
                            href={
                                checkoutStore.selectedBranding?.data_protection_payment_url ||
                                intl.formatMessage({ id: link })
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {chunks}
                        </a>
                    );
                },
                aAdditionalPaymentConditions: (chunks) => {
                    const link: MessageIDS = "links.payment_conditions";
                    return (
                        <a
                            href={
                                checkoutStore.selectedBranding?.terms_of_payment_url || intl.formatMessage({ id: link })
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {chunks}
                        </a>
                    );
                },
                aContactError: (chunks) => {
                    return (
                        <Link
                            onClick={() => {
                                runInAction(() => {
                                    checkoutStore.showIntercomBot = true;
                                });
                            }}
                            style={{ cursor: "pointer" }}
                            color={Colors.ERROR_HEX} // css variables not supported
                        >
                            {chunks}
                        </Link>
                    );
                },
                aPrivacyPolicyError: (chunks) => {
                    const link: MessageIDS = "links.privacy_policy";
                    return (
                        <Link
                            href={
                                checkoutStore.selectedBranding?.data_protection_payment_url ||
                                intl.formatMessage({ id: link })
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            color={Colors.ERROR_HEX} // css variables not supported
                        >
                            {chunks}
                        </Link>
                    );
                },
            }}
        />
    );
};

export { intl, intlMessages, setLocale, t };
