import { generalStore } from "../../stores/GeneralStore";

export function FormattedPercentage(props: { amount: number }) {
    return (
        <>
            {(props.amount / 100).toLocaleString(generalStore.locale, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            })}{" "}
            %
        </>
    );
}
