import { BaseRoutes } from "../../app/router/BaseRoutes";

const prefix = (route: string) => BaseRoutes.CHECKOUT + route;

export const CheckoutRoutes = {
    ROOT: prefix("/"),
    SELECT_PAYMENT: prefix("/select-payment"),
    PERSONAL_DATA: prefix("/personal-data"),
    PAYMENT_DATA: prefix("/payment-data"),
    MISSING_DATA: prefix("/missing-data"),
    REVIEW: prefix("/review"),
    SUCCESS: prefix("/success"),
    ERROR: prefix("/error"),
};
