import { default as dayjs } from "dayjs";
import { observer } from "mobx-react";
import { DATE_FORMAT_DE, DATE_FORMAT_EN } from "../../config";
import { generalStore } from "../../stores/GeneralStore";

type FormattedDateProps = {
    date?: string;
};

export function getFormattedDate(date?: string) {
    if (!date) {
        return "";
    }
    return dayjs(date)
        .format(generalStore.locale === "de" ? DATE_FORMAT_DE : DATE_FORMAT_EN)
        .toString();
}

export const FormattedDate = observer(({ date }: FormattedDateProps) => {
    return <span style={{ whiteSpace: "nowrap" }}>{getFormattedDate(date)}</span>;
});
