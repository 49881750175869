import React from "react";
import { t, tHtml } from "../../i18n/util";
import { checkoutStore } from "../../stores/CheckoutStore";
import { getAnonymizedIban } from "../../util/helpers";
import { Text } from "./Text";

export function SEPADirectDebitMandate(props: { iban: string | null; style?: React.CSSProperties }) {
    if (!props.iban) {
        return null;
    }

    const bankInformation = checkoutStore.checkoutSession?.bank_information;
    const bankShortName = bankInformation?.short_name ? bankInformation.short_name : bankInformation?.imprint.name;

    return (
        <div style={{ display: "flex", flexDirection: "column", ...props.style }}>
            <h3>{t("site.review.sepa.title")}</h3>
            <span style={{ marginTop: 16 }}>
                <Text
                    type="bodyS"
                    textStyle="body1"
                    style={{ marginTop: 16, marginBottom: 8 }}
                    data-id="sepaInformation_iban"
                >
                    {t("site.review.sepa.accountHolder")}
                </Text>
                {checkoutStore.personalInformation?.firstName} {checkoutStore.personalInformation?.lastName}
            </span>
            <span style={{ marginTop: 16 }}>
                <Text type="bodyS" textStyle="body1" data-id="sepaInformation_iban">
                    {t("site.review.sepa.iban")}
                </Text>
                {getAnonymizedIban({ numberOfVisibleCharsStart: 3, numberOfVisibleCharsEnd: 4, iban: props.iban })}
            </span>
            <Text type="bodyS" textStyle="headingsDark" style={{ marginTop: 16 }} data-id="sepaInformation_disclaimer">
                {t("site.review.sepa.disclaimer", {
                    bankName: bankInformation?.imprint.name,
                    streetName: bankInformation?.imprint.street_address,
                    postalCode: bankInformation?.imprint.postal_code,
                    city: bankInformation?.imprint.city,
                    creditorId: bankInformation?.creditor_identifier,
                    country: bankInformation?.imprint.country,
                    shortName: bankShortName,
                })}
            </Text>
            <Text type="bodyXS" textStyle="body2" style={{ marginTop: 16 }} data-id="sepaInformation_hint">
                {tHtml("site.review.sepa.hint")}
            </Text>
        </div>
    );
}
