import { Button, IconButton, useTheme } from "@mui/material";
import React from "react";
import { t } from "../../i18n/util";
import { IPersonalInformation } from "../../stores/CheckoutStore";
import { usePushRoute } from "../app/router/history";
import { CheckoutRoutes } from "../checkout/router/CheckoutRoutes";
import { Icon } from "../util/Icon";
import { Box } from "./Box";
import { Divider } from "./Divider";
import { getFormattedDate } from "./FormattedDate";
import { Text } from "./Text";
import { checkoutStore } from "../../stores/CheckoutStore";
import { generalStore } from "../../stores/GeneralStore";

export const PersonalInformationBox = (props: { personalInformation: IPersonalInformation | null }) => {
    const pushRoute = usePushRoute();
    const theme = useTheme();
    const [open, setOpen] = React.useState<boolean>(false);

    if (!props.personalInformation) {
        return null;
    }

    const countryInfo = checkoutStore.getCountryInfoForCode(props.personalInformation.country);
    const countryName = generalStore.locale === "de" ? countryInfo?.nameDE : countryInfo?.nameEN;

    return (
        <Box style={{ cursor: "pointer" }} onClick={() => setOpen(!open)}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 40,
                    }}
                >
                    <Text
                        type="bodyMBold"
                        style={{ marginBottom: 8, color: theme.palette.primary.main }}
                        data-id="personalInformation_name"
                    >
                        {props.personalInformation.firstName} {props.personalInformation.lastName}
                    </Text>
                    <Text type="bodyM" textStyle="body1" data-id="personalInformation_email">
                        {props.personalInformation.email}
                    </Text>
                </div>
                <IconButton onClick={() => setOpen(!open)} data-id="personalInformation_openDetails">
                    <Icon
                        name="chevronRight"
                        style={{ transform: open ? "rotate(-90deg)" : "rotate(90deg)", transition: ".2s transform" }}
                    />
                </IconButton>
            </div>
            {open && (
                <>
                    <Divider style={{ margin: "8px 0" }} />
                    <Text type="bodyS">{t("personal_information_box.birthdate")}</Text>
                    <Text type="bodyM" data-id="personalInformation_dob">
                        {getFormattedDate(props.personalInformation.birthdate)}
                    </Text>
                    <Text type="bodyS" style={{ marginTop: 8 }}>
                        {t("personal_information_box.billing_address")}
                    </Text>
                    <Text type="bodyM" data-id="personalInformation_address">
                        {props.personalInformation.businessClient && (
                            <>
                                {props.personalInformation.companyName} / {props.personalInformation.VATNumber}
                                <br />
                            </>
                        )}
                        {props.personalInformation.street}
                        <br />
                        {props.personalInformation.postalCode} {props.personalInformation.city}
                        <br />
                        {countryName}
                    </Text>
                    {!checkoutStore.eCommerceSession && (
                        <>
                            <Divider style={{ margin: "8px 0" }} />
                            <Button
                                onClick={() => {
                                    pushRoute(CheckoutRoutes.PERSONAL_DATA);
                                }}
                                data-id="personalInformation_editData"
                            >
                                {t("personal_information_box.edit_data")}
                            </Button>
                        </>
                    )}
                </>
            )}
        </Box>
    );
};
