import { Button, styled } from "@mui/material";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useIntercom } from "react-use-intercom";
import { checkoutStore } from "../../stores/CheckoutStore";
import { Icon } from "../util/Icon";
import { CookieBanner } from "./CookieBanner";

const StyledIntercomButton = styled(Button)(() => ({
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    right: 24,
    bottom: 24,
    height: 60,
    width: 60,
    minWidth: 60,
    borderRadius: 60,
    color: "white",
    zIndex: 10,
}));

export const IntercomButton = observer(() => {
    const { boot, show, shutdown } = useIntercom();
    const [showCookieBanner, setShowCookieBanner] = useState(false);

    const cookieDecision = checkoutStore.cookieDecision;
    const showIntercomBot = checkoutStore.showIntercomBot;
    const intercomAppId = checkoutStore.selectedBranding?.intercom_workspace_id;
    React.useEffect(() => {
        if (checkoutStore.cookieDecision === "NONE") {
            shutdown();
        } else if (checkoutStore.cookieDecision === "FUNCTIONAL" && intercomAppId) {
            boot();
        }
    }, [shutdown, boot, cookieDecision, intercomAppId]);

    React.useEffect(() => {
        if (showIntercomBot) {
            checkoutStore.cookieDecision !== "NONE" ? show() : handleShowCookieBanner();
            checkoutStore.showIntercomBot = false;
        }
    }, [showIntercomBot, show]);

    const handleShowCookieBanner = () => {
        setShowCookieBanner(true);
    };

    const handleSubmitCookieBanner = () => {
        setShowCookieBanner(false);
        boot();
        show();
    };
    const handleCloseCookieBanner = () => {
        setShowCookieBanner(false);
        shutdown();
    };

    if (checkoutStore.cookieDecision !== "NONE") {
        return null;
    }

    return (
        <>
            <StyledIntercomButton variant="contained" color="primary" size="small" onClick={handleShowCookieBanner}>
                <Icon name="intercom" size={32} />
            </StyledIntercomButton>
            <CookieBanner
                isOpen={showCookieBanner}
                onClose={handleCloseCookieBanner}
                onSubmit={handleSubmitCookieBanner}
            />
        </>
    );
});
