import { ModelError } from "../network/APITypes";

// TODO this should be removed and RespError should be used instead. This would Require the rename of the ModelError type as its currently defined ad Error in the swagger but renamed to ModelError in the generated code.
// However RespError is still generated with properties of the type Error which is not correct.
// This is a workaround for now. BE changes are required to fix this.
// https://a3.slack.com/archives/C03LH70NVKM/p1693916908156589
// UPDATE: It was decided to keep the ModelError name for now. This is a workaround for now.
export class APIError extends Error {
    statusCode: number;
    title = "";
    errors?: ModelError[];
    _links?: any;

    constructor(statusCode: number, title: string, errors?: ModelError[], _links?: any) {
        super();
        this.name = "APIError";
        this.title = title;
        this.statusCode = statusCode;
        this.errors = errors;
        this._links = _links;
    }
}
