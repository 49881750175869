import { Text } from "./Text";

export function ErrorBox(props: { children: React.ReactNode; style?: React.CSSProperties }) {
    return (
        <div style={{ padding: "8px 12px", background: "var(--color-error)", borderRadius: 8, ...props.style }}>
            <Text type="bodyMBold" style={{ color: "#fff" }}>
                {props.children}
            </Text>
        </div>
    );
}
