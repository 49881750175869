import { ThemeProvider } from "@mui/material";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { RawIntlProvider } from "react-intl";
import { IntercomProvider } from "react-use-intercom";
import { AppRouter } from "./components/app/router/AppRouter";
import { LoadingOverlay } from "./components/ui/LoadingOverlay";
import { addCustomYupValidators } from "./components/util/Yup";
import { INTERCOM_BASE_URL } from "./config";
import { useBranding } from "./hooks/useBranding";
import { useQuery } from "./hooks/useQuery";
import { intl, setLocale } from "./i18n/util";
import { checkoutStore } from "./stores/CheckoutStore";
import { debugStore } from "./stores/DebugStore";
import { generalStore } from "./stores/GeneralStore";
addCustomYupValidators();

const App: React.FunctionComponent = observer(() => {
    const { session_id }: { session_id?: string } = useQuery();
    const { branding_id }: { branding_id?: string } = useQuery();

    const selectedBranding =
        checkoutStore.checkoutSession?.branding_information ??
        checkoutStore.brandings.find((b) => b.sessionIds.includes(branding_id ?? ""))?.brandingInformation;

    const { branding } = useBranding(checkoutStore.failedToLoadCheckoutSession, selectedBranding);

    React.useEffect(() => {
        if (session_id) {
            runInAction(() => {
                checkoutStore.sessionId = session_id;
            });
        }
        if (session_id || !checkoutStore.sessionId) {
            checkoutStore.loadCheckoutSession();
        }
    }, [session_id]);

    React.useEffect(() => {
        if (selectedBranding) {
            checkoutStore.setBranding(selectedBranding);
        }
    }, [selectedBranding]);

    React.useEffect(() => {
        const browserLanguage = navigator.language.substring(0, 2);

        if (browserLanguage !== "de") {
            setLocale("en");
        }
    }, []);

    if (!checkoutStore.isRehydrated) {
        return <LoadingOverlay />;
    }

    if (!checkoutStore.failedToLoadCheckoutSession && !checkoutStore.checkoutSession?.branding_information) {
        return <LoadingOverlay />;
    }

    if (!branding) {
        return <LoadingOverlay />;
    }

    (window as any).debugStore = debugStore;

    return (
        <IntercomProvider
            appId={checkoutStore.selectedBranding?.intercom_workspace_id ?? ""}
            apiBase={INTERCOM_BASE_URL}
        >
            <ThemeProvider theme={branding}>
                <RawIntlProvider value={intl} key={generalStore.locale}>
                    <AppRouter />
                </RawIntlProvider>
            </ThemeProvider>
        </IntercomProvider>
    );
});

export default App;
