import { t } from "../../i18n/util";
import { CheckoutSessionDetail } from "../../network/APITypes";
import { Box } from "./Box";
import { Divider } from "./Divider";
import { FormattedAmount } from "./FormattedAmount";
import { Text } from "./Text";

export const Basket = (props: { checkoutSession: CheckoutSessionDetail | null }) => {
    if (
        !props.checkoutSession?.checkout_session.order_lines ||
        props.checkoutSession.checkout_session.order_lines.length === 0
    ) {
        return null;
    }

    return (
        <Box>
            <Text type="bodyMBold" style={{ marginBottom: 8 }}>
                {t("basket.title")}
            </Text>
            {props.checkoutSession.checkout_session.order_lines.map((item, index) => {
                return (
                    <div
                        style={{ display: "flex", justifyContent: "space-between", marginTop: 8 }}
                        key={`${item.name}-${index}`}
                    >
                        <Text type="bodyS" textStyle="body1">
                            {item.name}
                        </Text>
                        <Text type="bodyS" textStyle="body1" style={{ marginLeft: 8 }}>
                            <FormattedAmount amount={item.total_amount} />
                        </Text>
                    </div>
                );
            })}
            <Divider style={{ margin: "8px 0" }} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Text type="bodyS" textStyle="body1">
                    {t("basket.total_amount")}
                </Text>
                <Text type="bodyMBold" textStyle="body1" style={{ marginLeft: 8 }}>
                    <FormattedAmount amount={props.checkoutSession.checkout_session.amount} />
                </Text>
            </div>
        </Box>
    );
};
