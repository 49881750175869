import { TextField, TextFieldProps } from "@mui/material";
import { DatePickerProps, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FieldInputProps, FormikProps, FormikValues, getIn, useFormikContext } from "formik";
import * as React from "react";
import { t } from "../../i18n/util";
import { FieldError } from "./FieldError";

type IProps<Values extends FormikValues> = DatePickerProps<Date, Date> & {
    style?: React.CSSProperties;
    disableFuture?: boolean;
    disablePast?: boolean;
    disabled?: boolean;
    hideOptional?: boolean;
    minDate?: Date;
    maxDate?: Date;
    label?: string;
    field: FieldInputProps<string>;
    form: FormikProps<Values>;
    required?: boolean;
    type?: string;
    showValidationErrorText?: boolean;
    "data-id": string;
};

export const CustomDatePicker = <Values extends FormikValues>({
    style,
    label,
    type,
    required,
    field,
    form,
    onChange,
    minDate,
    maxDate,
    disableFuture,
    disablePast,
    disabled,
    showValidationErrorText = true,
    "data-id": dataId,
}: IProps<Values>) => {
    const formikContext = useFormikContext();
    const fieldRef = React.useRef<HTMLDivElement>(null);
    const fieldError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && !!fieldError;

    // scroll to first validation error when user submits form
    React.useEffect(() => {
        const firstError = Object.keys(formikContext.errors)[0];
        if (formikContext.isSubmitting && firstError === field.name) {
            fieldRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [formikContext.isSubmitting, formikContext.errors, field.name]);

    const handleChange = (date: Date | null, value?: string | undefined) => {
        form.setFieldValue(field.name, date ?? "", true);

        if (onChange) {
            onChange(date, value);
        }
    };

    const DatePickerTextField = (props: TextFieldProps) => (
        <TextField
            {...props}
            ref={fieldRef}
            required={required}
            name={field.name}
            onBlur={field.onBlur}
            fullWidth
            type={type}
            error={showError}
            variant="outlined"
            data-id={dataId}
            inputProps={{
                ...props.inputProps,
                placeholder: t("date.placeholder"),
            }}
        ></TextField>
    );

    return (
        <div style={style}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    disableFuture={disableFuture}
                    disablePast={disablePast}
                    disabled={disabled}
                    maxDate={maxDate}
                    minDate={minDate}
                    inputFormat={t("common.dateFormat")}
                    openTo="year"
                    value={field.value}
                    label={label ?? t("common.dateFormat")}
                    onChange={handleChange}
                    renderInput={DatePickerTextField}
                    views={["year", "month", "day"]}
                />
            </LocalizationProvider>
            {showValidationErrorText && <FieldError>{showError ? fieldError : ""}</FieldError>}
        </div>
    );
};
