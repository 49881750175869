// Define global colors here
export const Colors = {
    PRIMARY_500: "var(--color-primary-500)",
    PRIMARY_500_HEX: "#4221C6",
    PRIMARY_600: "var(--color-primary-600)",
    PRIMARY_600_HEX: "#311994",
    PRIMARY_700: "var(--color-primary-700)",
    PRIMARY_700_HEX: "#24126D",
    SECONDARY_500: "var(--color-secondary-500)",
    SECONDARY_500_HEX: "#EDCF5A",
    SECONDARY_600: "var(--color-secondary-600)",
    SECONDARY_600_HEX: "#E8C12B",
    SECONDARY_700: "var(--color-secondary-600)",
    SECONDARY_700_HEX: "#E1B819",
    GREY_200: "var(--color-grey-200)",
    GREY_200_HEX: "#F6F3FC",
    GREY_500: "var(--color-grey-500)",
    GREY_500_HEX: "#D7D7D7",
    GREY_700: "var(--color-grey-700)",
    GREY_700_HEX: "#4F4F4F",
    HEADINGS_DARK_HEX: "#23022E",
    LINK_GREY: "#717481",
    DARK: "#000000",
    LIGHT: "#FFFFFF",
    ERROR_HEX: "#E8637E",
    DEFAULT_BRANDING_PRIMARY_500: "#3F3C40",
    DEFAULT_BRANDING_PRIMARY_600: "#3E3B3F",
    DEFAULT_BRANDING_PRIMARY_700: "#3D3A3E",
    DEFAULT_BRANDING_SECONDARY_500: "#EDCF5A",
    DEFAULT_BRANDING_SECONDARY_600: "#E8C12B",
    DEFAULT_BRANDING_SECONDARY_700: "#E1B819",
};
