import { observer } from "mobx-react";
import { APIError } from "../../../errors/APIError";
import { t, tHtml } from "../../../i18n/util";
import { ModelError } from "../../../network/APITypes";
import { checkoutStore } from "../../../stores/CheckoutStore";
import { SectionHeader } from "../../ui/SectionHeader";
import { Text } from "../../ui/Text";
import React from "react";

class ErrorMessage {
    constructor(message?: string) {
        this.message = message ?? "";
    }
    message: string | React.ReactNode = "";
    style?: React.CSSProperties = { color: "var(--color-error)" };
}

function messagesFromError(error: APIError): ErrorMessage[] {
    return (error.errors ?? []).map((e) => {
        const errorMessage = new ErrorMessage();
        switch (e.error_code) {
            case "CHECKOUT_SESSION_CANCELLED":
                errorMessage.message = t("error.checkout_session_cancelled");
                break;
            case "CHECKOUT_SESSION_EXPIRED":
                errorMessage.message = t("error.checkout_session_expired");
                break;
            case "CHECKOUT_SESSION_ALREADY_ACCEPTED":
                errorMessage.message = t("error.checkout_session_already_accepted");
                errorMessage.style = undefined;
                break;
            case "CHECKOUT_SESSION_DECLINED":
                errorMessage.message = tHtml("error.checkout_session_declined", {
                    link: error._links?.documentation.href,
                });
                break;
        }

        return errorMessage;
    });
}

// PAYL-296: Special case: treat already accepted error as success
function isAlreadyAccepted(errors: ModelError[]): boolean {
    for (const e of errors) {
        if (e.error_code === "CHECKOUT_SESSION_ALREADY_ACCEPTED") {
            return true;
        }
    }

    return false;
}

function isDeclined(errors: ModelError[]) {
    for (const e of errors) {
        if (e.error_code === "CHECKOUT_SESSION_DECLINED") {
            return true;
        }
    }
    return false;
}

export const CheckoutOrderFailedSite = observer(() => {
    let title = t("error.generic.title");
    let messages: ErrorMessage[] = [new ErrorMessage(t("error.generic"))];

    let error: APIError | null = null;
    if (checkoutStore.failedToLoadCheckoutSession) {
        error = checkoutStore.loadCheckoutSessionError;
    } else if (checkoutStore.failedToSendCheckoutSession) {
        error = checkoutStore.sendCheckoutSessionError;
    }

    // PAYL-296: Special case: treat already accepted error as success
    let alreadyAccepted = false;
    if (error) {
        if (error.statusCode === 404) {
            messages = [new ErrorMessage(t("error.checkout_session.failed_to_load"))];
        } else if (error.statusCode === 400 && error.errors && error.errors.length > 0) {
            messages = messagesFromError(error);
            const declined = isDeclined(error.errors);
            alreadyAccepted = isAlreadyAccepted(error.errors);
            if (declined) {
                title = t("error.checkout_session_declined.title");
            }
            if (alreadyAccepted) {
                title = t("error.checkout_session_already_accepted.title");
            }
        }
    }

    return (
        <>
            <SectionHeader
                data-id="orderFailed_title"
                highlight={alreadyAccepted}
                error={!alreadyAccepted}
                icon={alreadyAccepted ? "checkCircle" : "circle"}
            >
                {title}
            </SectionHeader>
            {messages.map((m, i) => (
                <Text key={i} type="bodyM" textStyle="headingsDark" style={m.style}>
                    {m.message}
                </Text>
            ))}
        </>
    );
});
