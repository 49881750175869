import { Button } from "@mui/material";
import { Field, Formik } from "formik";
import { observer } from "mobx-react";
import * as Yup from "yup";
import { checkoutStore } from "../../../stores/CheckoutStore";
import { Basket } from "../../ui/Basket";
import { CustomInputField } from "../../ui/CustomInputField";
import { SectionHeader } from "../../ui/SectionHeader";

export const DebugSite = observer(() => {
    return (
        <>
            <SectionHeader>Zahlungsart wählen</SectionHeader>
            <Basket checkoutSession={checkoutStore.checkoutSession} />
            <Button>Weiter</Button>
            <Button variant="contained">Weiter</Button>
            <Button variant="contained" disabled>
                Weiter
            </Button>
            <Formik
                onSubmit={() => {
                    //
                }}
                initialValues={{}}
                validationSchema={Yup.object().shape({
                    name: Yup.string().trim(),
                })}
            >
                {({ isSubmitting, setFieldValue, errors, setFieldError, values }) => (
                    <>
                        <Field
                            component={CustomInputField}
                            multiline
                            label="Name"
                            name="name"
                            minRows={4}
                            maxRows={4}
                        />
                        <Field component={CustomInputField} label="E-Mail" name="email" />
                    </>
                )}
            </Formik>
        </>
    );
});
