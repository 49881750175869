import { useTheme } from "@mui/material";

import { checkoutStore } from "../../stores/CheckoutStore";

export const Header = () => {
    const theme = useTheme();
    return (
        <header
            style={{
                background: theme.palette.primary.main,
                padding: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                visibility: checkoutStore.selectedBranding ? "visible" : "hidden",
            }}
        >
            {!!checkoutStore.selectedBranding?.logo && (
                <img
                    src={checkoutStore.selectedBranding.logo}
                    alt="Header Logo"
                    style={{ width: "100%", maxWidth: 250 }}
                />
            )}
        </header>
    );
};
