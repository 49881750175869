import { Divider, MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import { ILocales } from "../../i18n/ILocales";
import { setLocale, t } from "../../i18n/util";
import { generalStore } from "../../stores/GeneralStore";
import { Colors } from "../util/Colors";
import { CookieBanner } from "./CookieBanner";
import { LinkButton } from "./LinkButton";

export type Option = {
    value: string | number;
    label: string;
    divider?: boolean;
};

const StyledDivider = styled(Divider)({
    background: Colors.LINK_GREY,
});

const StyledSelect = styled(Select)({
    fontSize: 12,
    fontWeight: 400,
    color: Colors.LINK_GREY,
    "& .MuiSelect-icon": {
        top: 0,
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: Colors.LIGHT,
    },
});

const StyledMenuItem = styled(MenuItem)({
    fontSize: 12,
    fontWeight: 400,
    color: Colors.LINK_GREY,
});

const StyledLinkButton = styled(LinkButton)({
    color: Colors.LINK_GREY,
    whiteSpace: "nowrap",
});

const localeOptions: Option[] = [
    { label: t("language.german"), value: "de" },
    { label: t("language.english"), value: "en" },
];

export const Footer = observer(() => {
    const [showCookieBanner, setShowCookieBanner] = React.useState(false);

    const handleChangeLocale = (event: SelectChangeEvent) => {
        setLocale(event.target.value as ILocales);
    };

    const handleClickCookies = () => {
        setShowCookieBanner(true);
    };

    const handleCloseCookieBanner = () => {
        setShowCookieBanner(false);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                backgroundColor: Colors.LIGHT,
                minHeight: 88,
                padding: `24px 8px 24px`,
                gap: 0,
            }}
        >
            <div style={{ flexGrow: 1, flexBasis: 0 }} />
            <div
                style={{
                    display: "flex",
                    gap: 8,
                    flexWrap: "wrap",
                }}
            >
                <StyledLinkButton className="caption" href={t("footer.link.privacyPolicy")}>
                    {t("footer.link.button.privacyPolicy")}
                </StyledLinkButton>
                <StyledDivider orientation="vertical" variant="middle" flexItem />
                <StyledLinkButton className="caption" href={t("footer.link.imprint")}>
                    {t("footer.link.button.imprint")}
                </StyledLinkButton>
                <StyledDivider orientation="vertical" variant="middle" flexItem />
                <StyledLinkButton className="caption" onClick={handleClickCookies}>
                    {t("footer.link.button.cookies")}
                </StyledLinkButton>
            </div>
            <CookieBanner
                showDecline
                isOpen={showCookieBanner}
                onClose={handleCloseCookieBanner}
                onSubmit={handleCloseCookieBanner}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexGrow: 1,
                    flexBasis: 0,
                }}
            >
                <StyledSelect
                    variant="standard"
                    label={t("common.language")}
                    value={generalStore.locale}
                    onChange={handleChangeLocale}
                    disableUnderline
                >
                    {localeOptions?.map((option) => (
                        <StyledMenuItem key={option.value} value={option.value} margin="normal">
                            {option.label}
                        </StyledMenuItem>
                    ))}
                </StyledSelect>
            </div>
        </div>
    );
});
