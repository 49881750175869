import { Backdrop, Button, IconButton } from "@mui/material";
import { runInAction } from "mobx";
import { t, tHtml } from "../../i18n/util";
import { checkoutStore } from "../../stores/CheckoutStore";
import { Icon } from "../util/Icon";

export const CookieBanner = ({
    isOpen,
    showDecline,
    onClose,
    onSubmit,
}: {
    isOpen: boolean;
    showDecline?: boolean;
    onClose: () => void;
    onSubmit: () => void;
}) => {
    const handleClose = () => {
        runInAction(() => {
            checkoutStore.cookieDecision = "NONE";
        });

        onClose();
    };

    const handleSubmit = () => {
        runInAction(() => {
            checkoutStore.cookieDecision = "FUNCTIONAL";
        });

        onSubmit();
    };

    return (
        <Backdrop open={isOpen} style={{ zIndex: 9999 }}>
            <section style={{ position: "fixed", bottom: 16 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        flexGrow: 1,
                        backgroundColor: "var(--color-background)",
                        padding: 24,
                        borderRadius: 8,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 8,
                            width: "100%",
                        }}
                    >
                        <h3>{t("cookies.title")}</h3>
                        {!showDecline && (
                            <IconButton onClick={handleClose}>
                                <Icon name="close" color="black" />
                            </IconButton>
                        )}
                    </div>
                    <p style={{ padding: "8px 0" }}>{tHtml("cookies.description")}</p>
                    {showDecline && (
                        <Button
                            style={{ marginTop: 24 }}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={handleClose}
                        >
                            {t("cookies.decline")}
                        </Button>
                    )}
                    <Button
                        style={{ marginTop: 16 }}
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={handleSubmit}
                    >
                        {t("cookies.acceptFunctional")}
                    </Button>
                </div>
            </section>
        </Backdrop>
    );
};
