import { Button } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { checkoutStore } from "../../../stores/CheckoutStore";
import { usePushRoute } from "../../app/router/history";
import { BackButton } from "../../ui/BackButton";
import { CustomInputField } from "../../ui/CustomInputField";
import { PersonalInformationBox } from "../../ui/PersonalInformationBox";
import { SectionHeader } from "../../ui/SectionHeader";
import { CheckoutRoutes } from "../router/CheckoutRoutes";

export const CheckoutPaymentDataSite = observer(() => {
    const pushRoute = usePushRoute();

    React.useEffect(() => {
        if (checkoutStore.eCommerceSession) {
            pushRoute(CheckoutRoutes.SELECT_PAYMENT);
        }
        if (!checkoutStore.hasEnteredPersonalInformation()) {
            pushRoute(CheckoutRoutes.PERSONAL_DATA);
        }
    }, [pushRoute]);

    return (
        <>
            <BackButton to={CheckoutRoutes.PERSONAL_DATA} data-id="paymentData_backButton" />
            <SectionHeader data-id="paymentData_title">{t("site.payment_data.title")}</SectionHeader>
            <PersonalInformationBox personalInformation={checkoutStore.personalInformation} />
            <Formik
                onSubmit={(values) => {
                    runInAction(() => {
                        checkoutStore.iban = values.iban;
                    });
                    pushRoute(CheckoutRoutes.REVIEW);
                }}
                initialValues={{
                    iban: checkoutStore.iban || "",
                }}
                validationSchema={Yup.object().shape({
                    iban: Yup.string()
                        .iban(t("site.payment_data.form.iban.error_invalid"))
                        .required(t("site.payment_data.form.iban.error_required"))
                        .trim(),
                })}
            >
                {({ isSubmitting, submitForm, setFieldValue, errors, setFieldError, values }) => (
                    <Form style={{ display: "flex", flexDirection: "column", marginTop: 24 }}>
                        <Field
                            component={CustomInputField}
                            label={t("site.payment_data.form.iban")}
                            name="iban"
                            data-id="paymentData_iban"
                            removeWhiteSpaces
                        />
                        <Button
                            variant="contained"
                            style={{ marginTop: 24 }}
                            type="submit"
                            onClick={submitForm}
                            data-id="paymentData_nextButton"
                        >
                            {t("site.payment_data.next")}
                        </Button>
                    </Form>
                )}
            </Formik>
        </>
    );
});
