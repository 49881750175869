import React from "react";

type ITextStyle = "headingsDark" | "body1" | "body2" | "headingsLight" | "bodyLight" | "textfields" | "primary500";

const textStyleColorVarMapping: { [k in ITextStyle]: string } = {
    headingsDark: "var(--text-headings-dark-color)",
    body1: "var(--text-body1-color)",
    body2: "var(--text-body2-color)",
    headingsLight: "var(--text-headings-light-color)",
    bodyLight: "var(--text-body-light-color)",
    textfields: "var(--text-textfields-color)",
    primary500: "var(--color-primary-500)",
};

export function Text(props: {
    type:
        | "h1"
        | "h2"
        | "h3"
        | "overline"
        | "bodyMBold"
        | "bodyM"
        | "bodyS"
        | "bodySBold"
        | "bodyXS"
        | "bodyXSBold"
        | "caption";
    textStyle?: ITextStyle;
    children: React.ReactNode | React.ReactNode[];
    style?: React.CSSProperties;
}) {
    return (
        <span
            className={props.type}
            style={{ color: props.textStyle ? textStyleColorVarMapping[props.textStyle] : undefined, ...props.style }}
        >
            {props.children}
        </span>
    );
}
