import { Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { t } from "../../../i18n/util";
import { CheckoutSession, PaymentOption } from "../../../network/APITypes";
import { checkoutStore } from "../../../stores/CheckoutStore";
import { Basket } from "../../ui/Basket";
import { ErrorBox } from "../../ui/ErrorBox";
import { PaymentRateSelection } from "../../ui/PaymentRateSelection";
import { PaymentTypeSelectionTermsDisclaimer } from "../../ui/PaymentTypeSelectionTermsDisclaimer";
import { SectionHeader } from "../../ui/SectionHeader";
import { Text } from "../../ui/Text";

export const CheckoutSelectPaymentMethodSite = observer(() => {
    const radioGroupRef = React.useRef<HTMLDivElement>(null);

    const [contractDownloaded, setContractDownloaded] = React.useState(false);
    const handleDownloadContract = () => {
        setContractDownloaded(true);
    };

    if (!checkoutStore.checkoutSession) {
        return null;
    }

    const paymentOptions = ((checkoutStore.checkoutSession.payment_options || []) as PaymentOption[]).map(
        (paymentOption, index) => {
            const isLast = index === (checkoutStore.checkoutSession?.payment_options.length || 0) - 1;

            let paymentMethodLabel = "unknown payment method";
            let paymentMethodDescription = "";
            if (paymentOption.payment_method === "invoice") {
                paymentMethodLabel = t("payment_method.invoice.label");
                paymentMethodDescription = t("payment_method.invoice.description", { days: paymentOption.due_days });
            } else if (paymentOption.payment_method === "installment") {
                paymentMethodLabel = t("payment_method.installment.label");
                paymentMethodDescription = t("payment_method.installment.description");
            } else if (paymentOption.payment_method === "direct_debit") {
                paymentMethodLabel = t("payment_method.direct_debit.label");
                paymentMethodDescription = t("payment_method.direct_debit.description");
            }

            return (
                <div key={paymentOption.payment_method}>
                    <FormControlLabel
                        value={paymentOption.payment_method}
                        control={<Radio style={{ marginTop: -8 }} />}
                        style={{ alignItems: "flex-start", padding: "16px 0" }}
                        label={
                            <div style={{ marginLeft: 4 }}>
                                <Text type="bodyMBold">{paymentMethodLabel}</Text>
                                {paymentMethodDescription && (
                                    <>
                                        <br />
                                        <Text type="bodyS" textStyle="body1">
                                            {paymentMethodDescription}
                                        </Text>
                                    </>
                                )}
                                <div style={{ cursor: "initial" }}>
                                    {checkoutStore.selectedPaymentMethod === "installment" &&
                                        checkoutStore.selectedPaymentMethod === paymentOption.payment_method && (
                                            <PaymentRateSelection
                                                basketAmount={checkoutStore.checkoutSession?.checkout_session.amount}
                                                installmentOptions={paymentOption.installment_options}
                                                style={{ marginTop: 16 }}
                                                onDownloadContract={handleDownloadContract}
                                            />
                                        )}
                                    {checkoutStore.selectedPaymentMethod === paymentOption.payment_method && (
                                        <PaymentTypeSelectionTermsDisclaimer
                                            paymentMethod={paymentOption.payment_method}
                                            selectedInstallmentOptionId={checkoutStore.selectedInstallmentOptionId}
                                            contractDownloaded={contractDownloaded}
                                        />
                                    )}
                                </div>
                            </div>
                        }
                        data-id={`paymentOption_${paymentOption.payment_method}`}
                    />
                    {!isLast && <Divider />}
                </div>
            );
        },
    );

    return (
        <>
            <Basket checkoutSession={checkoutStore.checkoutSession} />
            <SectionHeader>{t("site.select_payment_type.select_payment_type")}</SectionHeader>
            <RadioGroup
                ref={radioGroupRef}
                value={checkoutStore.selectedPaymentMethod}
                onChange={(event, value) => {
                    runInAction(() => {
                        checkoutStore.selectedPaymentMethod = value as CheckoutSession["payment_method"];
                    });

                    // avoid getting unfitting scroll position after changing payment methods - PAYL-227
                    if (radioGroupRef.current) {
                        radioGroupRef.current.scrollIntoView();
                    }
                }}
            >
                {paymentOptions.length > 0 ? (
                    paymentOptions
                ) : (
                    <ErrorBox>{t("site.select_payment_type.error_no_payment_options_available")}</ErrorBox>
                )}
            </RadioGroup>
        </>
    );
});
