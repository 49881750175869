import { Button } from "@mui/material";
import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import * as Yup from "yup";
import useFingerprinting from "../../../hooks/useFingerprinting";
import { t } from "../../../i18n/util";
import { checkoutStore } from "../../../stores/CheckoutStore";
import { generalStore } from "../../../stores/GeneralStore";
import { usePushRoute } from "../../app/router/history";
import { BackButton } from "../../ui/BackButton";
import { CustomDatePicker } from "../../ui/CustomDatePicker";
import { CustomInputField } from "../../ui/CustomInputField";
import { SectionHeader } from "../../ui/SectionHeader";
import { Text } from "../../ui/Text";
import { CheckoutRoutes } from "../router/CheckoutRoutes";
import { CustomCheckbox } from "../../ui/CustomCheckbox";

const FIRST_NAME_MAX_LENGTH = 200;
const LAST_NAME_MAX_LENGTH = 200;
const EMAIL_MAX_LENGTH = 254;
const PHONE_NUMBER_MAX_LENGTH = 100;
const STREET_MAX_LENGTH = 200;
const CITY_MAX_LENGTH = 100;
const POSTAL_CODE_MAX_LENGTH = 20;

export const CheckoutMissingDataSite = observer(() => {
    useFingerprinting();

    const pushRoute = usePushRoute();

    React.useEffect(() => {
        if (
            !checkoutStore.hasSelectedPaymentMethod() ||
            checkoutStore.allValuesSet ||
            !checkoutStore.eCommerceSession
        ) {
            pushRoute(CheckoutRoutes.SELECT_PAYMENT);
        }
    }, [pushRoute]);

    return (
        <>
            <BackButton to={CheckoutRoutes.SELECT_PAYMENT} data-id="personalData_backButton" />
            <SectionHeader>{t("site.missing_information.title")}</SectionHeader>
            <Text type="bodyS" textStyle="headingsDark" style={{ marginBottom: 16 }} data-id="personalData_description">
                {t("site.missing_information.description")}
            </Text>
            {!checkoutStore.personalDataComplete && <h3>{t("site.personal_information.title")}</h3>}
            <Formik
                onSubmit={(values) => {
                    runInAction(() => {
                        values.birthdate = dayjs(values.birthdate).format("YYYY-MM-DD");

                        checkoutStore.personalInformation = _.omit(values, ["iban"]);
                        checkoutStore.iban = values.iban;
                    });

                    pushRoute(CheckoutRoutes.REVIEW);
                }}
                initialValues={{
                    businessClient: checkoutStore.personalInformation?.businessClient || false,
                    companyName: checkoutStore.personalInformation?.companyName || undefined,
                    VATNumber: checkoutStore.personalInformation?.VATNumber || "",
                    salutation: checkoutStore.personalInformation?.salutation || "",
                    firstName: checkoutStore.personalInformation?.firstName || "",
                    lastName: checkoutStore.personalInformation?.lastName || "",
                    birthdate: checkoutStore.personalInformation?.birthdate || "",
                    email: checkoutStore.personalInformation?.email || "",
                    phoneNumber: checkoutStore.personalInformation?.phoneNumber || "",
                    street: checkoutStore.personalInformation?.street || "",
                    city: checkoutStore.personalInformation?.city || "",
                    postalCode: checkoutStore.personalInformation?.postalCode || "",
                    country: checkoutStore.personalInformation?.country || "",
                    iban: checkoutStore.iban || "",
                }}
                validationSchema={Yup.object().shape({
                    businessClient: Yup.boolean(),
                    companyName: Yup.string().when("businessClient", {
                        is: true,
                        then: Yup.string().required("site.personal_information.form.company_name.error_required"),
                    }),
                    VATNumber: Yup.string().when("businessClient", {
                        is: true,
                        then: Yup.string().required("site.personal_information.form.vat_number.error_required"),
                    }),
                    salutation: Yup.string()
                        .required(t("site.personal_information.form.salutation.error_required"))
                        .trim(),
                    firstName: Yup.string()
                        .required(t("site.personal_information.form.first_name.error_required"))
                        .trim()
                        .max(
                            FIRST_NAME_MAX_LENGTH,
                            t("site.personal_information.form.first_name.error_max_length", {
                                maxLength: FIRST_NAME_MAX_LENGTH,
                            }),
                        ),
                    lastName: Yup.string()
                        .required(t("site.personal_information.form.last_name.error_required"))
                        .trim()
                        .max(
                            LAST_NAME_MAX_LENGTH,
                            t("site.personal_information.form.last_name.error_max_length", {
                                maxLength: LAST_NAME_MAX_LENGTH,
                            }),
                        ),
                    birthdate: Yup.string()
                        .required(t("site.personal_information.form.birthdate.error_required"))
                        .test(
                            "is-date-valid",
                            t("site.personal_information.form.birthdate.error"),
                            (value) => dayjs(value).isValid() && dayjs(value).isBefore(dayjs()),
                        ),
                    email: Yup.string()
                        .required(t("site.personal_information.form.email.error_required"))
                        .email(t("site.personal_information.form.email.invalid_format"))
                        .trim()
                        .max(
                            EMAIL_MAX_LENGTH,
                            t("site.personal_information.form.email.error_max_length", {
                                maxLength: EMAIL_MAX_LENGTH,
                            }),
                        ),
                    phoneNumber: Yup.string()
                        .required(t("site.personal_information.form.phone_number.error_required"))
                        .trim()
                        .max(
                            PHONE_NUMBER_MAX_LENGTH,
                            t("site.personal_information.form.phone_number.error_max_length", {
                                maxLength: PHONE_NUMBER_MAX_LENGTH,
                            }),
                        ),
                    street: Yup.string()
                        .required(t("site.personal_information.form.street.error_required"))
                        .trim()
                        .max(
                            STREET_MAX_LENGTH,
                            t("site.personal_information.form.street.error_max_length", {
                                maxLength: STREET_MAX_LENGTH,
                            }),
                        ),
                    city: Yup.string()
                        .required(t("site.personal_information.form.city.error_required"))
                        .trim()
                        .max(
                            CITY_MAX_LENGTH,
                            t("site.personal_information.form.city.error_max_length", {
                                maxLength: CITY_MAX_LENGTH,
                            }),
                        ),
                    postalCode: Yup.string()
                        .required(t("site.personal_information.form.postal_code.error_required"))
                        .trim()
                        .max(
                            POSTAL_CODE_MAX_LENGTH,
                            t("site.personal_information.form.postal_code.error_max_length", {
                                maxLength: POSTAL_CODE_MAX_LENGTH,
                            }),
                        ),
                    country: Yup.string().required(t("site.personal_information.form.country.error_required")).trim(),
                    iban: Yup.string()
                        .iban(t("site.payment_data.form.iban.error_invalid"))
                        .required(t("site.payment_data.form.iban.error_required"))
                        .trim(),
                })}
            >
                {({ isSubmitting, submitForm, setFieldValue, errors, setFieldError, values }) => (
                    <Form style={{ display: "flex", flexDirection: "column" }}>
                        {checkoutStore.initialData?.businessClient === undefined && (
                            <Field
                                component={CustomCheckbox}
                                label={t("site.personal_information.form.business_client")}
                                name="businessClient"
                                data-id="personalData_businessClient"
                                checked={values.businessClient}
                            />
                        )}
                        {!checkoutStore.initialData?.salutation && (
                            <Field
                                component={CustomInputField}
                                label={t("site.personal_information.form.salutation")}
                                name="salutation"
                                select
                                selectOptions={[
                                    { value: "male", label: t("site.personal_information.form.salutation.male") },
                                    { value: "female", label: t("site.personal_information.form.salutation.female") },
                                    { value: "diverse", label: t("site.personal_information.form.salutation.diverse") },
                                ]}
                                data-id="personalData_salutation"
                            />
                        )}
                        {!checkoutStore.initialData?.firstName && (
                            <Field
                                component={CustomInputField}
                                label={t("site.personal_information.form.first_name")}
                                name="firstName"
                                data-id="personalData_firstName"
                            />
                        )}
                        {!checkoutStore.initialData?.lastName && (
                            <Field
                                component={CustomInputField}
                                label={t("site.personal_information.form.last_name")}
                                name="lastName"
                                data-id="personalData_lastName"
                            />
                        )}
                        {!checkoutStore.initialData?.birthdate && (
                            <Field
                                component={CustomDatePicker}
                                label={t("site.personal_information.form.birthdate")}
                                name="birthdate"
                                data-id="personalData_date"
                            />
                        )}
                        {!checkoutStore.initialData?.email && (
                            <Field
                                component={CustomInputField}
                                label={t("site.personal_information.form.email")}
                                name="email"
                                data-id="personalData_email"
                            />
                        )}
                        {!checkoutStore.initialData?.phoneNumber && (
                            <Field
                                component={CustomInputField}
                                label={t("site.personal_information.form.phone_number")}
                                name="phoneNumber"
                                data-id="personalData_phoneNumber"
                            />
                        )}
                        {!checkoutStore.billingAddressComplete && (
                            <h3 style={{ marginBottom: 16 }}>{t("site.personal_information.form.billing_address")}</h3>
                        )}
                        {values.businessClient && (
                            <>
                                {!checkoutStore.initialData?.companyName && (
                                    <Field
                                        component={CustomInputField}
                                        label={t("site.personal_information.form.company_name")}
                                        name="companyName"
                                        data-id="personalData_companyName"
                                    />
                                )}
                                {!checkoutStore.initialData?.VATNumber && (
                                    <Field
                                        component={CustomInputField}
                                        label={t("site.personal_information.form.vat_number")}
                                        name="VATNumber"
                                        data-id="personalData_VATNumber"
                                    />
                                )}
                            </>
                        )}
                        {!checkoutStore.initialData?.street && (
                            <Field
                                component={CustomInputField}
                                label={t("site.personal_information.form.street")}
                                name="street"
                                data-id="personalData_street"
                            />
                        )}
                        {!checkoutStore.initialData?.city && (
                            <Field
                                component={CustomInputField}
                                label={t("site.personal_information.form.city")}
                                name="city"
                                data-id="personalData_city"
                            />
                        )}
                        {!checkoutStore.initialData?.postalCode && (
                            <Field
                                component={CustomInputField}
                                label={t("site.personal_information.form.postal_code")}
                                name="postalCode"
                                data-id="personalData_postalCode"
                            />
                        )}
                        {!checkoutStore.initialData?.country && (
                            <Field
                                component={CustomInputField}
                                label={t("site.personal_information.form.country")}
                                name="country"
                                select
                                selectOptions={checkoutStore.checkoutSession?.allowed_countries.map(
                                    (allowedCountry) => {
                                        const countryInfo = checkoutStore.getCountryInfoForCode(allowedCountry);
                                        return {
                                            value: countryInfo?.code,
                                            label:
                                                generalStore.locale === "de" && countryInfo?.nameDE
                                                    ? countryInfo?.nameDE
                                                    : countryInfo?.nameEN,
                                        };
                                    },
                                )}
                                data-id="personalData_country"
                            />
                        )}
                        {!checkoutStore.paymentDataComplete && (
                            <h3 style={{ marginBottom: 16 }}>{t("site.payment_data.title")}</h3>
                        )}
                        {!checkoutStore.initialData?.iban && (
                            <Field
                                component={CustomInputField}
                                label={t("site.payment_data.form.iban")}
                                name="iban"
                                data-id="paymentData_iban"
                                removeWhiteSpaces
                            />
                        )}
                        <Button
                            variant="contained"
                            style={{ marginTop: 24 }}
                            type="submit"
                            onClick={submitForm}
                            data-id="personalData_nextButton"
                        >
                            {t("site.personal_information.next")}
                        </Button>
                    </Form>
                )}
            </Formik>
        </>
    );
});
