import { observer } from "mobx-react";
import React from "react";
import { t, MessageIdsTypeguard, MessageIDS, tHtml } from "../../../i18n/util";
import { checkoutStore } from "../../../stores/CheckoutStore";
import { usePushRoute } from "../../app/router/history";
import { SectionHeader } from "../../ui/SectionHeader";
import { Text } from "../../ui/Text";
import { CheckoutRoutes } from "../router/CheckoutRoutes";
import { LoadingOverlay } from "../../ui/LoadingOverlay";

export const CheckoutSuccessSite = observer(() => {
    const pushRoute = usePushRoute();

    React.useEffect(() => {
        if (checkoutStore.selectedPaymentMethod === "invoice") {
            if (!checkoutStore.hasEnteredPersonalInformation()) {
                pushRoute(CheckoutRoutes.PERSONAL_DATA);
            }
        } else if (!checkoutStore.hasEnteredPaymentData()) {
            pushRoute(CheckoutRoutes.PAYMENT_DATA);
        } else if (!checkoutStore.hasSuccessfullyPlacedOrder()) {
            pushRoute(CheckoutRoutes.REVIEW);
        }
        if (checkoutStore.successDetails?.completion_action === "redirect") {
            window.location.replace((checkoutStore.successDetails._links as any).redirect_url.href);
            // TODO: _links is currently incorrectly Typed as string by the API
            // Remove once its correctly Typed
            // https://a3.slack.com/archives/C03LH70NVKM/p1694778290413929
        }
    }, [pushRoute]);

    if (checkoutStore.successDetails?.completion_action === "redirect") {
        return <LoadingOverlay />;
    }

    const getSuccessText = () => {
        let id: MessageIDS;
        if (
            checkoutStore.successDetails?.text_identifier &&
            MessageIdsTypeguard(checkoutStore.successDetails?.text_identifier)
        ) {
            id = checkoutStore.successDetails?.text_identifier;
        } else {
            id = "SUCCESS_TEXT_DEFAULT";
        }

        let option;
        switch (checkoutStore.selectedPaymentMethod) {
            case "invoice":
                option = t("successText.option.invoice");
                break;
            case "installment":
                option = t("successText.option.installment");
                break;
            case "direct_debit":
                option = t("successText.option.directDebit");
                break;
        }

        return t(id, { option });
    };

    const showSuccessImage =
        checkoutStore.successDetails?.completion_action === "qr_code" &&
        !!checkoutStore.successDetails?.image &&
        !(checkoutStore.checkoutSession?.checkout_configuration.receipt_type === "short_receipt");

    const showSuccessPin =
        checkoutStore.successDetails?.completion_action === "pin" && checkoutStore.successDetails?.pin;

    let sectionHeaderTitle = "-";
    let sectionDescriptionId: MessageIDS | undefined = undefined;

    if (checkoutStore.selectedPaymentMethod === "invoice") {
        sectionHeaderTitle = t("site.success.invoice.title");
    }
    // Installment has a distinction between digital and print receipts each have distinct titles
    // Print receipts are further distinguished by short and long receipts
    if (checkoutStore.selectedPaymentMethod === "installment") {
        if (checkoutStore.checkoutSession?.checkout_configuration.receipt_type === "digital") {
            sectionHeaderTitle = t("site.success.installment.title.digital");
        } else {
            sectionHeaderTitle = t("site.success.installment.title.print");
            // Short and long receipts have different descriptions
            if (checkoutStore.checkoutSession?.checkout_configuration.receipt_type === "short_receipt")
                sectionDescriptionId = "site.success.description.installment.short";
            else {
                sectionDescriptionId = "site.success.description.installment.long";
            }
        }
    }
    if (checkoutStore.selectedPaymentMethod === "direct_debit") {
        sectionHeaderTitle = t("site.success.direct_debit.title");
    }

    return (
        <>
            <SectionHeader highlight icon="checkCircle">
                {sectionHeaderTitle}
            </SectionHeader>
            {sectionDescriptionId && (
                <Text type="bodyM" textStyle="headingsDark">
                    {tHtml(sectionDescriptionId)}
                    <br />
                    <br />
                </Text>
            )}
            <Text type="bodyM" textStyle="headingsDark">
                {getSuccessText()}
            </Text>
            {showSuccessImage && <SuccessImage image={checkoutStore.successDetails?.image} />}
            {showSuccessPin && <SuccessPin pin={checkoutStore.successDetails?.pin} />}
        </>
    );
});

const SuccessImage = ({ image }: { image?: string }) => {
    return (
        <img //  https://stackoverflow.com/a/42399865
            style={{ marginTop: 24, width: "100%", maxWidth: 400, alignSelf: "center" }}
            alt="success_image"
            src={`data:image/png;base64,${image}`}
        />
    );
};

const SuccessPin = ({ pin }: { pin?: string }) => {
    if (!pin) return null;
    return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 32 }}>
            {pin.split("").map((char, index) => (
                <div
                    style={{
                        backgroundColor: "white",
                        borderRadius: 16,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 32,
                        fontWeight: 600,
                        width: 64,
                        height: 64,
                    }}
                >
                    {char}
                </div>
            ))}
        </div>
    );
};
