export function sleep(timeMs: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, timeMs);
    });
}

export function getFormattedIban(iban?: string) {
    if (!iban) {
        return "";
    }

    return iban
        .replace(/[^\dA-Z]/g, "")
        .replace(/(.{4})/g, "$1 ")
        .trim();
}

export function getAnonymizedIban(options: {
    iban: string;
    numberOfVisibleCharsStart: number;
    numberOfVisibleCharsEnd: number;
}) {
    if (!options.iban) {
        return "";
    }

    const trimmedIban = options.iban.trim();

    const visiblePartStart = trimmedIban.substring(0, options.numberOfVisibleCharsStart);
    const visiblePartEnd = trimmedIban.substring(trimmedIban.length - options.numberOfVisibleCharsEnd);

    const anonymizedPartLength =
        trimmedIban.length - options.numberOfVisibleCharsStart - options.numberOfVisibleCharsEnd;
    const anonymizedPart = anonymizedPartLength > 0 ? "•".repeat(anonymizedPartLength) : "";

    const formattedIban = (visiblePartStart + anonymizedPart + visiblePartEnd).replace(/(.{4})/g, "$1 ");

    return formattedIban.trim();
}
