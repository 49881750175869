import { compact } from "lodash";
import * as queryString from "query-string";

// this hook is using window instead of useLocation() because it´s called outside the router
export const useQuery = (options?: { arrayKeys?: string[] }) => {
    const result = queryString.parse(window.location.search, {
        parseBooleans: true,
        arrayFormat: "bracket",
    }) as any;
    if (options?.arrayKeys) {
        options.arrayKeys.forEach((key) => {
            if (!Array.isArray(result[key])) {
                // Convert single elements to array
                result[key] = [result[key]];
            }
            result[key] = compact(result[key]);
        });
    }

    return result;
};
