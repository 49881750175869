import { useTheme } from "@mui/material";
import React from "react";
import { t } from "../../i18n/util";
import { CheckoutInstallmentOption, CheckoutSession } from "../../network/APITypes";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";
import { Divider } from "./Divider";
import { FormattedAmount, getFormattedAmount } from "./FormattedAmount";
import { FormattedDate } from "./FormattedDate";
import { Text } from "./Text";
import { checkoutStore } from "../../stores/CheckoutStore";

function ReviewPaymentPlanItem(props: {
    label: string;
    value: React.ReactNode;
    highlightValue?: boolean;
    onUnfold?: () => void;
    open?: boolean;
}) {
    const theme = useTheme();

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: !!props.onUnfold ? "pointer" : undefined,
            }}
            onClick={props.onUnfold}
        >
            <div style={{ display: "flex", alignItems: "center" }}>
                <Text type="bodySBold" textStyle="headingsDark">
                    {props.label}
                </Text>
                {props.onUnfold && (
                    <Icon
                        name="chevronRight"
                        style={{
                            transform: props.open ? "rotate(-90deg)" : "rotate(90deg)",
                            transition: ".2s transform",
                            marginLeft: 8,
                            padding: 8,
                        }}
                    />
                )}
            </div>
            <Text
                type="bodySBold"
                style={{
                    color: props.highlightValue ? theme.palette.primary.main : Colors.HEADINGS_DARK_HEX,
                    textAlign: "right",
                }}
            >
                {props.value}
            </Text>
        </div>
    );
}

function InstallmentPlanItem(props: {
    amount: React.ReactNode;
    dueDate: React.ReactNode;
    rate: string;
    index?: boolean;
}) {
    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Text type="bodySBold" textStyle="headingsDark" style={{ marginLeft: 8 }}>
                {props.rate}
            </Text>
            <Text type="bodyS" textStyle="headingsDark" style={{ marginRight: 40 }}>
                {props.dueDate}
            </Text>
            <Text type="bodyS" textStyle="headingsDark">
                {props.amount}
            </Text>
        </div>
    );
}

export function ReviewPaymentPlan(props: {
    totalAmount?: CheckoutSession["amount"] | null;
    installmentOption?: CheckoutInstallmentOption | null;
    selectedPaymentMethod: CheckoutSession["payment_method"] | null;
    selectedInstallmentOptionId: CheckoutInstallmentOption["installment_option_id"] | null;
    style?: React.CSSProperties;
}) {
    const [openInstallmentPlan, setOpenInstallmentPlan] = React.useState(false);

    let paymentPlan: React.ReactNode = "-";

    if (props.selectedPaymentMethod === "installment") {
        paymentPlan = t("site.review.payment_info.payment_plan.installment", {
            count: props.installmentOption?.number_of_payments,
        });
    } else if (props.selectedPaymentMethod === "invoice") {
        paymentPlan = t("site.review.payment_info.payment_plan.invoice", {
            count: checkoutStore.getDueDays(),
        });
    } else if (props.selectedPaymentMethod === "direct_debit") {
        paymentPlan = t("site.review.payment_info.payment_plan.direct_debit");
    }

    const handleUnfoldDetails = () => {
        setOpenInstallmentPlan(!openInstallmentPlan);
    };

    return (
        <div style={props.style}>
            <ReviewPaymentPlanItem
                label={t("site.review.payment_info.payment_plan")}
                value={paymentPlan}
                highlightValue
            />
            {props.selectedPaymentMethod === "installment" && (
                <>
                    <Divider style={{ margin: "16px 0" }} />
                    <ReviewPaymentPlanItem
                        label={t("site.review.payment_info.installment_plan")}
                        onUnfold={handleUnfoldDetails}
                        open={openInstallmentPlan}
                        value={
                            <>
                                <FormattedAmount amount={props.installmentOption?.monthly_amount} />
                                {t("payment_method.installment.rate_selection.title", {
                                    numberOfPayments: props.installmentOption?.number_of_payments,
                                })}
                            </>
                        }
                    />
                    {openInstallmentPlan && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 16,
                                marginTop: 24,
                                cursor: "pointer",
                            }}
                            onClick={handleUnfoldDetails}
                        >
                            {props.installmentOption?.installment_rates.map((rate, index) => {
                                return (
                                    <InstallmentPlanItem
                                        key={rate.progressive_rate_number}
                                        amount={<FormattedAmount amount={rate.monthly_amount} />}
                                        dueDate={<FormattedDate date={rate.due_date} />}
                                        rate={t("site.review.payment_info.installment_plan.rate", {
                                            count: rate.progressive_rate_number,
                                        })}
                                    />
                                );
                            })}
                        </div>
                    )}
                </>
            )}

            <Divider style={{ margin: "16px 0" }} />
            <ReviewPaymentPlanItem
                label={
                    props.selectedPaymentMethod === "installment"
                        ? t("site.review.payment_info.installment.total")
                        : t("site.review.payment_info.total")
                }
                value={props.totalAmount ? getFormattedAmount(props.totalAmount) : "-"}
            />
        </div>
    );
}
