import { Button } from "@mui/material";
import { t } from "../../../i18n/util";
import { BaseRoutes } from "../../app/router/BaseRoutes";
import { usePushRoute } from "../../app/router/history";

export const NotFoundSite = () => {
    const pushRoute = usePushRoute();

    return (
        <>
            <h1 style={{ margin: 24, textAlign: "center" }}>{t("screen.not_found.title")}</h1>
            <Button
                variant="contained"
                onClick={() => {
                    pushRoute(BaseRoutes.ROOT);
                }}
            >
                {t("screen.not_found.button")}
            </Button>
        </>
    );
};
