import { useEffect, useState } from "react";

export type UseScriptStatus = "idle" | "loading" | "ready" | "error";
export interface UseScriptOptions {
    shouldPreventLoad?: boolean;
    removeOnUnmount?: boolean;
}
export type UseScriptElement = "script" | "link";

// Cached script statuses
const cachedScriptStatuses: Record<string, UseScriptStatus | undefined> = {};

function getNode(src: string, element: UseScriptElement) {
    const node: HTMLScriptElement | HTMLLinkElement | null =
        element === "link"
            ? document.querySelector<HTMLLinkElement>(`link[href="${src}"]`)
            : document.querySelector<HTMLScriptElement>(`script[src="${src}"]`);
    const status = node?.getAttribute("data-status") as UseScriptStatus | undefined;

    return {
        node,
        status,
    };
}

function useScript({
    src,
    options,
    element = "script",
    id,
}: {
    src: string | null;
    options?: UseScriptOptions;
    element?: "script" | "link";
    id?: string;
}): UseScriptStatus {
    const [status, setStatus] = useState<UseScriptStatus>(() => {
        if (!src || options?.shouldPreventLoad) {
            return "idle";
        }

        if (typeof window === "undefined") {
            // SSR Handling - always return 'loading'
            return "loading";
        }

        return cachedScriptStatuses[src] ?? "loading";
    });

    useEffect(() => {
        if (!src || options?.shouldPreventLoad) {
            return;
        }

        const cachedScriptStatus = cachedScriptStatuses[src];
        if (cachedScriptStatus === "ready" || cachedScriptStatus === "error") {
            // If the script is already cached, set its status immediately
            setStatus(cachedScriptStatus);
            return;
        }

        // Fetch existing script element by src
        // It may have been added by another instance of this hook
        const script = getNode(src, element);
        let node = script.node;

        if (!node) {
            if (element === "link") {
                node = document.createElement(element);
                node.rel = "stylesheet";
                node.type = "text/css";
                node.href = src;
                node.setAttribute("data-status", "loading");
            } else {
                // Create script element and add it to document body
                node = document.createElement(element);
                node.src = src;
                node.async = true;
                node.setAttribute("data-status", "loading");
            }
            if (id) {
                // set session Id for cleanup
                node.setAttribute("sessionId", id);
            }
            document.body.appendChild(node);

            // Store status in attribute on script
            // This can be read by other instances of this hook
            const setAttributeFromEvent = (event: Event) => {
                const scriptStatus: UseScriptStatus = event.type === "load" ? "ready" : "error";

                node?.setAttribute("data-status", scriptStatus);
            };

            node.addEventListener("load", setAttributeFromEvent);
            node.addEventListener("error", setAttributeFromEvent);
        } else {
            // Grab existing script status from attribute and set to state.
            setStatus(script.status ?? cachedScriptStatus ?? "loading");
        }

        // Script event handler to update status in state
        // Note: Even if the script already exists we still need to add
        // event handlers to update the state for *this* hook instance.
        const setStateFromEvent = (event: Event) => {
            const newStatus = event.type === "load" ? "ready" : "error";
            setStatus(newStatus);
            cachedScriptStatuses[src] = newStatus;
        };

        // Add event listeners
        node.addEventListener("load", setStateFromEvent);
        node.addEventListener("error", setStateFromEvent);

        // Remove event listeners on cleanup
        return () => {
            if (node) {
                node.removeEventListener("load", setStateFromEvent);
                node.removeEventListener("error", setStateFromEvent);
            }

            if (node && options?.removeOnUnmount) {
                node.remove();
            }
        };
    }, [src, options?.shouldPreventLoad, options?.removeOnUnmount, element, id]);

    return status;
}

export default useScript;
