import { makeAutoObservable, runInAction } from "mobx";
import { clearPersistedStore, makePersistable, stopPersisting } from "mobx-persist-store";
import { v4 as uuid } from "uuid";
import CountryCodes from "../components/util/country_codes.json";
import { MOCK_ENABLED } from "../config";
import { API } from "../network/API";
import {
    CheckoutSessionDetail,
    CountryEnum,
    CreateCheckoutSessionOrder,
    GenderEnum,
    CheckoutInstallmentOption,
    ResponseCheckoutSessionOrder,
    CheckoutSession,
    CheckoutSessionDetailBrandingInformation,
} from "../network/APITypes";
import { APIError } from "../errors/APIError";
import { MOCK_CHECKOUT_SESSION, MOCK_SUCCESS } from "./MockValues";

export interface IPersonalInformation {
    businessClient: boolean;
    companyName?: string;
    VATNumber?: string;
    salutation: string;
    firstName: string;
    lastName: string;
    birthdate: string;
    email: string;
    phoneNumber: string;
    street: string;
    city: string;
    postalCode: string;
    country: string;
}

export type IdentifiedBrandingInformation = {
    sessionIds: string[];
    brandingInformation: CheckoutSessionDetailBrandingInformation;
};

export type CookiePermission = "NONE" | "FUNCTIONAL";
export type IFingerprint = {
    deviceToken: string;
    sessionId: string;
    partnerMerchandId: string;
    paylaPartnerId: string;
};

class CheckoutStore {
    checkoutSession: CheckoutSessionDetail | null = null;
    successDetails: ResponseCheckoutSessionOrder | null = null;

    selectedPaymentMethod: CheckoutSession["payment_method"] | null | null = null;
    selectedInstallmentOptionId: CheckoutInstallmentOption["installment_option_id"] | null = null;
    personalInformation: IPersonalInformation | null = null;
    iban: string | null = null;

    failedToLoadCheckoutSession = false;
    loadCheckoutSessionError: APIError | null = null;

    failedToSendCheckoutSession = false;
    sendCheckoutSessionError: APIError | null = null;

    isRehydrated = false;
    cookieDecision: CookiePermission = "NONE";
    showIntercomBot = false;
    sessionId: string | null = null;
    fingerprint?: IFingerprint = undefined;

    brandings: IdentifiedBrandingInformation[] = [];
    selectedBranding?: CheckoutSessionDetailBrandingInformation;

    initialData?: IPersonalInformation & { iban: string } = undefined;
    eCommerceSession?: boolean;
    personalDataComplete = false;
    paymentDataComplete = false;
    billingAddressComplete = false;
    allValuesSet = false;

    constructor() {
        makeAutoObservable(this);
        void this.initPersistence();
    }

    setBranding(branding: CheckoutSessionDetailBrandingInformation) {
        this.selectedBranding = branding;
    }

    generateFingerprint() {
        if (
            !this.fingerprint &&
            this.checkoutSession?.shop_information.external_shop_reference_id &&
            this.checkoutSession?.shop_information.partner_reference_id
        ) {
            const sessionId = uuid();
            const partnerMerchandId = this.checkoutSession?.shop_information.external_shop_reference_id;
            const paylaPartnerId = this.checkoutSession?.shop_information.partner_reference_id;
            const deviceToken = `${paylaPartnerId}_${partnerMerchandId}_${sessionId}`;
            this.fingerprint = {
                deviceToken,
                sessionId,
                partnerMerchandId,
                paylaPartnerId,
            };
        }
    }

    clear() {
        this.clearFingerprint();
        this.sessionId = null;
    }

    clearFingerprint() {
        this.removeFingerprintingElements();
        this.fingerprint = undefined;
    }

    removeFingerprintingElements = () => {
        const script = document.querySelector(`script[sessionId*="${this.fingerprint?.sessionId}"]`);
        const link = document.querySelector(`link[sessionId*="${this.fingerprint?.sessionId}"]`);
        if (script) {
            script.remove();
        }
        if (link) {
            link.remove();
        }
    };

    setLoadCheckoutSessionError(failed: boolean, error?: APIError) {
        this.failedToLoadCheckoutSession = failed;
        if (failed && error) {
            this.loadCheckoutSessionError = error;
        } else {
            this.loadCheckoutSessionError = null;
        }
    }

    async loadCheckoutSession() {
        if (!this.sessionId) {
            this.setLoadCheckoutSessionError(true);
            return;
        }

        try {
            const response = MOCK_ENABLED
                ? MOCK_CHECKOUT_SESSION
                : await API.getCheckoutSession({ sessionId: this.sessionId });
            this.checkoutSession = response;
            this.storeBranding(response.branding_information, this.sessionId);
            this.eCommerceSession = response.checkout_session.channel === "ecommerce";
            this.setInitialData(response.checkout_session);
            this.setLoadCheckoutSessionError(false);
        } catch (error) {
            this.setLoadCheckoutSessionError(true, error instanceof APIError ? error : undefined);
        }
    }

    storeBranding(branding: CheckoutSessionDetailBrandingInformation, sessionId: string) {
        if (this.brandings.some((b) => b.sessionIds.includes(sessionId))) {
            return;
        }
        const previouslyStoredBrandingIdx = this.brandings.findIndex(
            (b) => JSON.stringify(b.brandingInformation) === JSON.stringify(branding),
        );
        if (previouslyStoredBrandingIdx !== -1) {
            this.brandings[previouslyStoredBrandingIdx].sessionIds.push(sessionId);
            return;
        }
        this.brandings.push({ sessionIds: [sessionId], brandingInformation: branding });
    }

    setInitialData(session: CheckoutSession) {
        const billingAddress = session.billing_address;

        this.personalInformation = {
            businessClient: session.consumer?.resource === "organization",
            companyName: billingAddress?.organization_name,
            VATNumber: session.consumer?.vat_id,
            salutation: session.consumer?.gender ?? "",
            firstName: billingAddress?.first_name ?? "",
            lastName: billingAddress?.last_name ?? "",
            birthdate: session.consumer?.date_of_birth ?? "",
            email: billingAddress?.email ?? "",
            phoneNumber: billingAddress?.phone ?? "",
            street: billingAddress?.street_address ?? "",
            city: billingAddress?.city ?? "",
            postalCode: billingAddress?.postal_code ?? "",
            country: billingAddress?.country ?? "",
        };

        this.iban = session.bank_account?.iban ?? "";
        this.initialData = { ...this.personalInformation, iban: this.iban };

        this.personalDataComplete =
            this.initialData?.businessClient !== undefined &&
            !!this.initialData?.salutation &&
            !!this.initialData?.firstName &&
            !!this.initialData?.lastName &&
            !!this.initialData?.birthdate &&
            !!this.initialData?.email &&
            !!this.initialData?.phoneNumber;

        this.billingAddressComplete =
            (!!this.initialData?.companyName || this.initialData?.businessClient === false) &&
            (!!this.initialData?.VATNumber || this.initialData.businessClient === false) &&
            !!this.initialData?.street &&
            !!this.initialData?.city &&
            !!this.initialData?.postalCode &&
            !!this.initialData?.country;

        this.paymentDataComplete = !!this.initialData.iban || session.payment_method === "invoice";
        this.allValuesSet = this.personalDataComplete && this.paymentDataComplete && this.billingAddressComplete;

        this.selectedPaymentMethod = session.payment_method;
        this.selectedInstallmentOptionId = session.installment_option_id?.trim() ?? null; // TODO remove trim() when backend is fixed
    }

    setSendCheckoutSessionError(failed: boolean, error?: APIError) {
        this.failedToSendCheckoutSession = failed;
        if (failed && error) {
            this.sendCheckoutSessionError = error;
        } else {
            this.sendCheckoutSessionError = null;
        }
    }

    async postCheckoutSession() {
        if (!this.sessionId || !this.selectedPaymentMethod) {
            this.setSendCheckoutSessionError(true);
            return;
        }

        try {
            const createCheckOutSession: CreateCheckoutSessionOrder = {
                short_checkout_session_id: this.sessionId,
                device_token: this.fingerprint?.deviceToken,
                payment_method: this.selectedPaymentMethod,
                installment_option_id:
                    this.selectedPaymentMethod === "installment"
                        ? this.selectedInstallmentOptionId ?? undefined
                        : undefined,
                billing_address: {
                    first_name: this.personalInformation?.firstName ?? "",
                    last_name: this.personalInformation?.lastName ?? "",
                    email: this.personalInformation?.email ?? "",
                    phone: this.personalInformation?.phoneNumber,
                    city: this.personalInformation?.city ?? "",
                    country: (this.personalInformation?.country as CountryEnum) ?? "",
                    postal_code: this.personalInformation?.postalCode ?? "",
                    street_address: this.personalInformation?.street ?? "",
                    organization_name:
                        this.personalInformation?.businessClient && this.personalInformation?.companyName
                            ? this.personalInformation?.companyName
                            : undefined,
                },
                bank_account:
                    this.selectedPaymentMethod === "direct_debit" || this.selectedPaymentMethod === "installment"
                        ? {
                              account_holder: `${this.personalInformation?.firstName} ${this.personalInformation?.lastName}`,
                              iban: this.iban ?? "",
                          }
                        : undefined,
                consumer: {
                    resource: this.personalInformation?.businessClient ? "organization" : "personal",
                    date_of_birth: this.personalInformation?.birthdate ?? "",
                    gender: this.personalInformation?.salutation as GenderEnum,
                    vat_id:
                        this.personalInformation?.businessClient && this.personalInformation?.VATNumber
                            ? this.personalInformation?.VATNumber
                            : undefined,
                },
            };

            const response = MOCK_ENABLED
                ? MOCK_SUCCESS
                : await API.postCheckOutSession({
                      sessionId: this.sessionId,
                      body: createCheckOutSession,
                  });

            // When you do a postCheckOutSession() and it's declined you still get a 201 with
            // the checkout_session_status = declined. When you later do a GET on the same session
            // you get a 400 with CHECKOUT_SESSION_DECLINED. Since we want to tread those two cases
            // in the same way we throw an APIError with the same error_code here.
            if (response.checkout_session_status === "declined") {
                const error = new APIError(
                    400,
                    "Bad Request",
                    [
                        {
                            error_code: "CHECKOUT_SESSION_DECLINED",
                            reason: "The selected payment method for this payment process was declined.",
                        },
                    ],
                    response._links,
                );

                if (response.completion_action === "redirect" && response._links.redirect_url?.href) {
                    window.location.href = response._links.redirect_url?.href;
                }
                throw error;
            }

            this.successDetails = response;
            this.setSendCheckoutSessionError(false);

            return response.checkout_session_status;
        } catch (error) {
            this.setSendCheckoutSessionError(true, error instanceof APIError ? error : undefined);
        }
    }

    getSelectedPaymentOption() {
        if (!this.selectedPaymentMethod) {
            return null;
        }

        return (
            this.checkoutSession?.payment_options.find(
                (option) => option.payment_method === this.selectedPaymentMethod,
            ) || null
        );
    }

    getSelectedInstallmentOption() {
        if (!this.selectedPaymentMethod || !this.selectedInstallmentOptionId) {
            return null;
        }

        return (
            this.getSelectedPaymentOption()?.installment_options?.find(
                (option) => option.installment_option_id.trim() === this.selectedInstallmentOptionId?.trim(), // TODO remove trim() when backend is fixed
            ) || null
        );
    }

    getCountryInfoForCode(countryCode: string) {
        return CountryCodes.find((cc) => {
            return cc.code === countryCode;
        });
    }

    hasSelectedPaymentMethod() {
        if (this.selectedPaymentMethod === "installment") {
            return !!this.selectedInstallmentOptionId;
        } else {
            return !!this.selectedPaymentMethod;
        }
    }

    getDueDays() {
        const invoice = this.checkoutSession?.payment_options.find((option) => option.payment_method === "invoice");
        if (invoice) {
            return invoice.due_days;
        }
    }

    hasEnteredPersonalInformation() {
        return (
            !!this.personalInformation?.firstName &&
            !!this.personalInformation?.lastName &&
            !!this.personalInformation?.birthdate
        );
    }

    hasEnteredPaymentData() {
        return !!this.iban;
    }

    hasSuccessfullyPlacedOrder() {
        return !!this.successDetails;
    }

    // Returns the final total amount depending on the selected payment method.
    getTotalAmount() {
        if (this.selectedPaymentMethod === "installment") {
            return this.getSelectedInstallmentOption()?.total_amount;
        } else {
            return this.checkoutSession?.checkout_session.amount;
        }
    }

    initPersistence = async () => {
        try {
            await makePersistable(this, {
                name: "checkoutStore",
                properties: [
                    "selectedPaymentMethod",
                    "selectedInstallmentOptionId",
                    "personalInformation",
                    "iban",
                    "cookieDecision",
                    "sessionId",
                    "brandings",
                    "checkoutSession",
                ],
                storage: window.localStorage,
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.isRehydrated = true;
            });
        }
    };

    stopAndClearPersistence = async () => {
        await clearPersistedStore(this);
        stopPersisting(this);
    };
}

const checkoutStore = new CheckoutStore();

export async function resetCheckoutStore() {
    await checkoutStore.stopAndClearPersistence();
    window.location.reload();
}

export { checkoutStore };
