import { Button } from "@mui/material";
import { observer } from "mobx-react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { BASE_NAME, DEPLOYMENT_ENV, LOADING_INDICATOR_DELAY_MS } from "../../../config";
import { checkoutStore, resetCheckoutStore } from "../../../stores/CheckoutStore";
import { generalStore } from "../../../stores/GeneralStore";
import { CheckoutRoutes } from "../../checkout/router/CheckoutRoutes";
import { CheckoutOrderFailedSite } from "../../checkout/sites/CheckoutOrderFailedSite";
import { CheckoutPaymentDataSite } from "../../checkout/sites/CheckoutPaymentDataSite";
import { CheckoutPersonalDataSite } from "../../checkout/sites/CheckoutPersonalDataSite";
import { CheckoutReviewSite } from "../../checkout/sites/CheckoutReviewSite";
import { CheckoutSelectPaymentMethodSite } from "../../checkout/sites/CheckoutSelectPaymentMethodSite";
import { CheckoutSuccessSite } from "../../checkout/sites/CheckoutSuccessSite";
import { DebugSite } from "../../shared/sites/DebugSite";
import { NotFoundSite } from "../../shared/sites/NotFoundSite";
import { Footer } from "../../ui/Footer";

import { Header } from "../../ui/Header";
import { IntercomButton } from "../../ui/IntercomButton";
import { LoadingOverlay } from "../../ui/LoadingOverlay";
import { SiteWrapper } from "../../ui/SiteWrapper";
import { BaseRoutes } from "./BaseRoutes";
import { RoutingManager } from "./RoutingManager";
import ScrollToTop from "./ScrollToTop";
import { CheckoutMissingDataSite } from "../../checkout/sites/CheckoutMissingDataSite";

export const AppRouter = observer(() => {
    if (!checkoutStore.isRehydrated) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <Header />
            <SiteWrapper>
                <BrowserRouter basename={BASE_NAME || "/"}>
                    <ScrollToTop />
                    <RoutingManager>
                        <Routes>
                            {checkoutStore.failedToLoadCheckoutSession ? (
                                <Route path="*" element={<CheckoutOrderFailedSite />} />
                            ) : (
                                <>
                                    <Route path={BaseRoutes.ROOT} element={<Navigate to={CheckoutRoutes.ROOT} />} />
                                    <Route
                                        path={CheckoutRoutes.ROOT}
                                        element={<Navigate to={CheckoutRoutes.SELECT_PAYMENT} />}
                                    />
                                    <Route
                                        path={CheckoutRoutes.SELECT_PAYMENT}
                                        element={<CheckoutSelectPaymentMethodSite />}
                                    />
                                    <Route path={CheckoutRoutes.PERSONAL_DATA} element={<CheckoutPersonalDataSite />} />
                                    <Route path={CheckoutRoutes.PAYMENT_DATA} element={<CheckoutPaymentDataSite />} />
                                    <Route path={CheckoutRoutes.MISSING_DATA} element={<CheckoutMissingDataSite />} />
                                    <Route path={CheckoutRoutes.REVIEW} element={<CheckoutReviewSite />} />
                                    <Route path={CheckoutRoutes.SUCCESS} element={<CheckoutSuccessSite />} />
                                    <Route path={CheckoutRoutes.ERROR} element={<CheckoutOrderFailedSite />} />
                                    <Route path="*" element={<NotFoundSite />} />
                                </>
                            )}
                            <Route path={"/debug"} element={<DebugSite />} />
                        </Routes>
                    </RoutingManager>
                </BrowserRouter>

                {checkoutStore.selectedBranding?.intercom_workspace_id && <IntercomButton />}

                {DEPLOYMENT_ENV === "development" && (
                    <>
                        <p style={{ marginTop: 40, color: "var(--color-error)", fontWeight: "bold" }}>
                            Below content is only visible on development server for testing purposes.
                        </p>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                resetCheckoutStore();
                            }}
                            style={{ marginTop: 16 }}
                        >
                            Reset checkout store and reload site
                        </Button>
                        <div style={{ padding: "16px 24px" }}>
                            Test data:
                            <ul style={{ listStylePosition: "inside", marginTop: 8 }}>
                                <li>
                                    <b>IBAN:</b> AT483200000012345864
                                </li>
                                <li>
                                    <b>IBAN:</b> DE91100000000123456789
                                </li>
                                <li>
                                    <b>IBAN:</b> AL35202111090000000001234567
                                </li>
                            </ul>
                        </div>
                    </>
                )}
            </SiteWrapper>
            <Footer />
            {generalStore.isLoading && <LoadingOverlay delayMs={LOADING_INDICATOR_DELAY_MS} />}
        </>
    );
});
