import React from "react";
import { Icon, IIconNames } from "../util/Icon";

export const SectionHeader = (props: {
    children: React.ReactNode;
    highlight?: boolean;
    error?: boolean;
    icon?: IIconNames;
    style?: React.CSSProperties;
}) => {
    let headerColor = undefined;

    if (props.highlight) {
        headerColor = "var(--color-primary-500)";
    }

    if (props.error) {
        headerColor = "var(--color-error)";
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "24px 0 16px",
                ...props.style,
            }}
        >
            <h2
                style={{
                    color: headerColor,
                }}
            >
                {props.children}
            </h2>
            {props.icon && <Icon name={props.icon} style={{ marginLeft: 40 }} color={headerColor} />}
        </div>
    );
};
