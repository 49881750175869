import { Button } from "@mui/material";
import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import * as Yup from "yup";
import useFingerprinting from "../../../hooks/useFingerprinting";
import { t } from "../../../i18n/util";
import { checkoutStore } from "../../../stores/CheckoutStore";
import { generalStore } from "../../../stores/GeneralStore";
import { usePushRoute } from "../../app/router/history";
import { BackButton } from "../../ui/BackButton";
import { CustomDatePicker } from "../../ui/CustomDatePicker";
import { CustomCheckbox } from "../../ui/CustomCheckbox";
import { SectionHeader } from "../../ui/SectionHeader";
import { Text } from "../../ui/Text";
import { CheckoutRoutes } from "../router/CheckoutRoutes";
import { CustomInputField } from "../../ui/CustomInputField";

const FIRST_NAME_MAX_LENGTH = 200;
const LAST_NAME_MAX_LENGTH = 200;
const EMAIL_MAX_LENGTH = 254;
const PHONE_NUMBER_MAX_LENGTH = 100;
const STREET_MAX_LENGTH = 200;
const CITY_MAX_LENGTH = 100;
const POSTAL_CODE_MAX_LENGTH = 20;

export const CheckoutPersonalDataSite = observer(() => {
    useFingerprinting();

    const pushRoute = usePushRoute();

    React.useEffect(() => {
        if (!checkoutStore.hasSelectedPaymentMethod() || checkoutStore.eCommerceSession) {
            pushRoute(CheckoutRoutes.SELECT_PAYMENT);
        }
    }, [pushRoute]);

    return (
        <>
            <BackButton to={CheckoutRoutes.SELECT_PAYMENT} data-id="personalData_backButton" />
            <SectionHeader>{t("site.personal_information.title")}</SectionHeader>
            <Text type="bodyS" textStyle="headingsDark" style={{ marginBottom: 16 }} data-id="personalData_description">
                {t("site.personal_information.description")}
            </Text>
            <Formik
                onSubmit={(values) => {
                    runInAction(() => {
                        values.birthdate = dayjs(values.birthdate).format("YYYY-MM-DD");
                        checkoutStore.personalInformation = values;
                    });

                    if (checkoutStore.selectedPaymentMethod === "invoice") {
                        pushRoute(CheckoutRoutes.REVIEW);
                    } else {
                        pushRoute(CheckoutRoutes.PAYMENT_DATA);
                    }
                }}
                initialValues={{
                    businessClient:
                        checkoutStore.personalInformation?.businessClient ||
                        checkoutStore.checkoutSession?.checkout_session.consumer?.resource === "organization" ||
                        false,
                    companyName:
                        checkoutStore.personalInformation?.companyName ||
                        checkoutStore.checkoutSession?.checkout_session.billing_address?.organization_name ||
                        "",
                    VATNumber:
                        checkoutStore.personalInformation?.VATNumber ||
                        checkoutStore.checkoutSession?.checkout_session.consumer?.vat_id ||
                        "",
                    salutation:
                        checkoutStore.personalInformation?.salutation ||
                        checkoutStore.checkoutSession?.checkout_session.consumer?.gender ||
                        "",
                    firstName:
                        checkoutStore.personalInformation?.firstName ||
                        checkoutStore.checkoutSession?.checkout_session.billing_address?.first_name ||
                        "",
                    lastName:
                        checkoutStore.personalInformation?.lastName ||
                        checkoutStore.checkoutSession?.checkout_session.billing_address?.last_name ||
                        "",
                    birthdate:
                        checkoutStore.personalInformation?.birthdate ||
                        checkoutStore.checkoutSession?.checkout_session.consumer?.date_of_birth ||
                        "",
                    email:
                        checkoutStore.personalInformation?.email ||
                        checkoutStore.checkoutSession?.checkout_session.billing_address?.email ||
                        "",
                    phoneNumber:
                        checkoutStore.personalInformation?.phoneNumber ||
                        checkoutStore.checkoutSession?.checkout_session.billing_address?.phone ||
                        "",
                    street:
                        checkoutStore.personalInformation?.street ||
                        checkoutStore.checkoutSession?.checkout_session.billing_address?.street_address ||
                        "",
                    city:
                        checkoutStore.personalInformation?.city ||
                        checkoutStore.checkoutSession?.checkout_session.billing_address?.city ||
                        "",
                    postalCode:
                        checkoutStore.personalInformation?.postalCode ||
                        checkoutStore.checkoutSession?.checkout_session.billing_address?.postal_code ||
                        "",
                    country:
                        checkoutStore.personalInformation?.country ||
                        checkoutStore.checkoutSession?.checkout_session.billing_address?.country ||
                        "",
                }}
                validationSchema={Yup.object().shape({
                    businessClient: Yup.boolean(),
                    companyName: Yup.string().when("businessClient", {
                        is: true,
                        then: Yup.string().required("site.personal_information.form.company_name.error_required"),
                    }),
                    VATNumber: Yup.string().when("businessClient", {
                        is: true,
                        then: Yup.string().required("site.personal_information.form.vat_number.error_required"),
                    }),
                    salutation: Yup.string()
                        .required(t("site.personal_information.form.salutation.error_required"))
                        .trim(),
                    firstName: Yup.string()
                        .required(t("site.personal_information.form.first_name.error_required"))
                        .trim()
                        .max(
                            FIRST_NAME_MAX_LENGTH,
                            t("site.personal_information.form.first_name.error_max_length", {
                                maxLength: FIRST_NAME_MAX_LENGTH,
                            }),
                        ),
                    lastName: Yup.string()
                        .required(t("site.personal_information.form.last_name.error_required"))
                        .trim()
                        .max(
                            LAST_NAME_MAX_LENGTH,
                            t("site.personal_information.form.last_name.error_max_length", {
                                maxLength: LAST_NAME_MAX_LENGTH,
                            }),
                        ),
                    birthdate: Yup.string()
                        .required(t("site.personal_information.form.birthdate.error_required"))
                        .test(
                            "is-date-valid",
                            t("site.personal_information.form.birthdate.error"),
                            (value) => dayjs(value).isValid() && dayjs(value).isBefore(dayjs()),
                        ),
                    email: Yup.string()
                        .required(t("site.personal_information.form.email.error_required"))
                        .email(t("site.personal_information.form.email.invalid_format"))
                        .trim()
                        .max(
                            EMAIL_MAX_LENGTH,
                            t("site.personal_information.form.email.error_max_length", {
                                maxLength: EMAIL_MAX_LENGTH,
                            }),
                        ),
                    phoneNumber: Yup.string()
                        .required(t("site.personal_information.form.phone_number.error_required"))
                        .trim()
                        .max(
                            PHONE_NUMBER_MAX_LENGTH,
                            t("site.personal_information.form.phone_number.error_max_length", {
                                maxLength: PHONE_NUMBER_MAX_LENGTH,
                            }),
                        ),
                    street: Yup.string()
                        .required(t("site.personal_information.form.street.error_required"))
                        .trim()
                        .max(
                            STREET_MAX_LENGTH,
                            t("site.personal_information.form.street.error_max_length", {
                                maxLength: STREET_MAX_LENGTH,
                            }),
                        ),
                    city: Yup.string()
                        .required(t("site.personal_information.form.city.error_required"))
                        .trim()
                        .max(
                            CITY_MAX_LENGTH,
                            t("site.personal_information.form.city.error_max_length", {
                                maxLength: CITY_MAX_LENGTH,
                            }),
                        ),
                    postalCode: Yup.string()
                        .required(t("site.personal_information.form.postal_code.error_required"))
                        .trim()
                        .max(
                            POSTAL_CODE_MAX_LENGTH,
                            t("site.personal_information.form.postal_code.error_max_length", {
                                maxLength: POSTAL_CODE_MAX_LENGTH,
                            }),
                        ),
                    country: Yup.string().required(t("site.personal_information.form.country.error_required")).trim(),
                })}
            >
                {({ isSubmitting, submitForm, setFieldValue, errors, setFieldError, values }) => (
                    <Form style={{ display: "flex", flexDirection: "column" }}>
                        <Field
                            component={CustomCheckbox}
                            label={t("site.personal_information.form.business_client")}
                            name="businessClient"
                            data-id="personalData_businessClient"
                            checked={values.businessClient}
                        />
                        <Field
                            component={CustomInputField}
                            label={t("site.personal_information.form.salutation")}
                            name="salutation"
                            select
                            selectOptions={[
                                { value: "male", label: t("site.personal_information.form.salutation.male") },
                                { value: "female", label: t("site.personal_information.form.salutation.female") },
                                { value: "diverse", label: t("site.personal_information.form.salutation.diverse") },
                            ]}
                            data-id="personalData_salutation"
                        />
                        <Field
                            component={CustomInputField}
                            label={t("site.personal_information.form.first_name")}
                            name="firstName"
                            data-id="personalData_firstName"
                        />
                        <Field
                            component={CustomInputField}
                            label={t("site.personal_information.form.last_name")}
                            name="lastName"
                            data-id="personalData_lastName"
                        />
                        <Field
                            component={CustomDatePicker}
                            label={t("site.personal_information.form.birthdate")}
                            name="birthdate"
                            data-id="personalData_date"
                        />
                        <Field
                            component={CustomInputField}
                            label={t("site.personal_information.form.email")}
                            name="email"
                            data-id="personalData_email"
                        />
                        <Field
                            component={CustomInputField}
                            label={t("site.personal_information.form.phone_number")}
                            name="phoneNumber"
                            data-id="personalData_phoneNumber"
                        />
                        <h3 style={{ marginBottom: 16 }}>{t("site.personal_information.form.billing_address")}</h3>
                        {values.businessClient && (
                            <>
                                <Field
                                    component={CustomInputField}
                                    label={t("site.personal_information.form.company_name")}
                                    name="companyName"
                                    data-id="personalData_companyName"
                                />
                                <Field
                                    component={CustomInputField}
                                    label={t("site.personal_information.form.vat_number")}
                                    name="VATNumber"
                                    data-id="personalData_VATNumber"
                                />
                            </>
                        )}
                        <Field
                            component={CustomInputField}
                            label={t("site.personal_information.form.street")}
                            name="street"
                            data-id="personalData_street"
                        />
                        <Field
                            component={CustomInputField}
                            label={t("site.personal_information.form.city")}
                            name="city"
                            data-id="personalData_city"
                        />
                        <Field
                            component={CustomInputField}
                            label={t("site.personal_information.form.postal_code")}
                            name="postalCode"
                            data-id="personalData_postalCode"
                        />
                        <Field
                            component={CustomInputField}
                            label={t("site.personal_information.form.country")}
                            name="country"
                            select
                            selectOptions={checkoutStore.checkoutSession?.allowed_countries.map((allowedCountry) => {
                                const countryInfo = checkoutStore.getCountryInfoForCode(allowedCountry);
                                return {
                                    value: countryInfo?.code,
                                    label:
                                        generalStore.locale === "de" && countryInfo?.nameDE
                                            ? countryInfo?.nameDE
                                            : countryInfo?.nameEN,
                                };
                            })}
                            data-id="personalData_country"
                        />
                        <Button
                            variant="contained"
                            style={{ marginTop: 24 }}
                            type="submit"
                            onClick={submitForm}
                            data-id="personalData_nextButton"
                        >
                            {t("site.personal_information.next")}
                        </Button>
                    </Form>
                )}
            </Formik>
        </>
    );
});
