import { Link } from "react-router-dom";
import { t } from "../../i18n/util";

import { Icon } from "../util/Icon";

export function BackButton(props: { to: string; onClick?: () => void }) {
    return (
        <Link
            style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: "#000",
                WebkitTapHighlightColor: "transparent", // Hide click shadow on ios safari
            }}
            to={props.to}
            onClick={props.onClick}
            data-id="back_button"
        >
            <Icon name="arrowLeft" style={{ marginRight: 8 }} />
            {t("general.back")}
        </Link>
    );
}
